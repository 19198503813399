import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useArenaOutputStore = defineStore('arenaOutput', () => {
    // state
    const outputJobPk = ref(null)
    const arenasOutputLoading = ref(true)
    const arenaOutputAllTechAreaLoaded = ref(false)
    const arenaOutputAllTechAreaNodes = ref([])
    const arenaOutputTechAreaNodes = ref(new Set())
    const arenaOutputTechAreaSelections = ref(new Set())
    const arenaOutputTechAreaSelectionsOld = ref(new Set())
    const allCompetitorData = ref([])
    const arenaOutputActiveTab = ref(1)
    const showTargetCompanyModal = ref(null)
    const targetCompany = ref([])
    const newTargetCompany = ref([])
    const targetCompanyLoading = ref(false)
    const initLoad = ref(false)
    const drillInTargetId = ref(null)
    const showDrillIn = ref(false)
    const groupByUltimateParent = ref(true)

    // actions
    const resetState = (saveNodes = false) => {
        outputJobPk.value = null
        arenaOutputTechAreaNodes.value = new Set()
        arenaOutputTechAreaSelections.value = new Set()
        arenaOutputTechAreaSelectionsOld.value = new Set()
        arenaOutputAllTechAreaNodes.value = []
        arenasOutputLoading.value = true
        arenaOutputAllTechAreaLoaded.value = false
        arenaOutputActiveTab.value = 1
        targetCompanyLoading.value = false
        targetCompany.value = []
        newTargetCompany.value = []
        drillInTargetId.value = null
        showDrillIn.value = false
        groupByUltimateParent.value = true
    }

    const softResetState = () => {
        arenaOutputActiveTab.value = 1
        targetCompanyLoading.value = false
        targetCompany.value = []
        newTargetCompany.value = []
    }

    const techAreaIdCollection = computed((state) => {
        return [...arenaOutputTechAreaSelections.value].map((item) => item.nodeId)
    })

    return {
        outputJobPk,
        arenasOutputLoading,
        arenaOutputTechAreaNodes,
        resetState,
        arenaOutputActiveTab,
        arenaOutputAllTechAreaLoaded,
        arenaOutputAllTechAreaNodes,
        arenaOutputTechAreaSelections,
        arenaOutputTechAreaSelectionsOld,
        techAreaIdCollection,
        showTargetCompanyModal,
        targetCompany,
        allCompetitorData,
        initLoad,
        targetCompanyLoading,
        newTargetCompany,
        drillInTargetId,
        showDrillIn,
        groupByUltimateParent,
    }
})
