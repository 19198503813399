<template>
    <div class="funding-rounds-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        :title="$t('funding.fundingFor', [entityStore.entity.name])"
                        style="position: relative"
                    >
                        <div class="card-content">
                            <p v-if="companyCrunchbaseUrl" class="crunch-link">
                                {{ $t('funding.financialDataBy') }}
                                <a :href="companyCrunchbaseUrl" target="_blank">{{
                                    $t('funding.crunchbase')
                                }}</a>
                            </p>
                            <div class="metric-holder d-flex mb-5">
                                <div class="metric mr-10">
                                    <p class="grey01--text bold mb-1">
                                        {{ $t('funding.numberOfFundingRounds') }}
                                    </p>
                                    <p class="large">{{ fundingRoundsCount }}</p>
                                </div>
                                <div class="metric">
                                    <p class="grey01--text bold mb-1">
                                        {{ $t('funding.totalFundingAmount') }}
                                    </p>
                                    <p class="large">
                                        {{
                                            filters.abbreviate(
                                                entityStore.entity.total_funding_usd,
                                                2
                                            )
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="table-holder">
                                <MoatTable
                                    style="width: 100%; height: 400px"
                                    :class="themeClass"
                                    :column-defs="colDefs"
                                    :suppress-auto-size="true"
                                    :row-data="tableData"
                                    @grid-ready="gridInit"
                                />
                            </div>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, inject, computed } from 'vue'
import * as entityApi from '@/api/entities'
import { useEntityStore } from '@/stores'
import { useI18n } from 'vue-i18n'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateComparator } from '@/components/moatTable/helpers/compare.js'

const { t } = useI18n()
const logger = inject('logger')
const filters = inject('filters')
const entityStore = useEntityStore()

const companyCrunchbaseUrl = ref('cheese')
const tableData = ref([])
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'announcedOn',
        flex: 1,
        valueGetter: (p) => {
            if (p.data) {
                return filters.toUTCString(p.data.announcedOn)
            }
        },
        comparator: dateComparator,
    },
    {
        field: 'name',
        flex: 1,
    },
    {
        field: 'investorCount',
        flex: 1,
    },
    {
        field: 'moneyRaised',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.moneyRaised, 2)
            }
        },
    },
    {
        field: 'postMoneyValuationUsd',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return filters.abbreviate(p.data.postMoneyValuationUsd, 2)
            }
        },
    },
    {
        field: 'leadInvestors',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.leadInvestors?.replaceAll('), ', ')\n')
            }
        },
    },
    {
        field: 'otherInvestors',
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.otherInvestors?.replaceAll('), ', ')\n')
            }
        },
    },
])
onMounted(() => {
    getFunding()
})

onBeforeUnmount(() => {})

const fundingRoundsCount = computed(() => {
    if (!tableData.value) {
        return 0
    }
    return tableData.value.length
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const getFunding = async () => {
    try {
        const { data } = await entityApi.getEntityFunding(entityStore.entity.aon_entity_pk)
        tableData.value = data

        const [fundingItem] = data
        companyCrunchbaseUrl.value = fundingItem?.companyCrunchbaseUrl
    } catch (err) {
        logger.error(err)
    }
}
</script>

<style lang="scss" scoped>
.funding-rounds-card {
    width: 100%;
    position: relative;

    .crunch-link {
        position: absolute;
        top: 25px;
        right: 20px;
    }
}
</style>
