<template>
    <div
        class="global-search w-full"
        :class="props.watchlistPk ? 'pa-4 alt-style' : ''"
        v-click-outside="
            () => {
                showResults = false
            }
        "
    >
        <AonInput
            ref="searchInput"
            v-model="searchTerm"
            search-input
            id="global-search"
            :placeholder="t(`search.placeholder.${context}`)"
            @clear="clearSearch"
            @click="onClick"
            @keyup="liveSearch"
            @keyup.enter="searchAllPage(null)"
        />
        <div
            class="dropdown-results white w-100"
            :class="[
                { 'elevation-5': !props.watchlistPk },
                { 'alt-style': props.watchlistPk },
                { 'add-border': companyResults.length > 0 || loadingResults },
            ]"
        >
            <AonSpinner v-if="loadingResults" class="ma-auto" :scale="0.5" />
            <!-- // TODO DRY up these two very similar context results blocks -->
            <template v-if="resultsVisible">
                <!-- Companies -->
                <div v-if="hasCompaniesContext" class="company-results">
                    <div
                        v-if="!props.watchlistPk"
                        class="totals d-flex align-items-center justify-content-between px-2 py-3"
                    >
                        <div class="wrap d-flex align-items-center large bold grey02--text">
                            <font-awesome-icon :icon="`fas fa-building`" />
                            <p class="grey02--text bold ml-2">
                                {{ t('search.companies') }} <span>{{ companyResultsTotal }}</span>
                            </p>
                        </div>
                        <p
                            class="link bold"
                            data-cy="search-all-companies"
                            @click="searchAllPage('companies')"
                        >
                            {{ t('search.search.companies') }}
                        </p>
                    </div>
                    <div class="results">
                        <p v-if="!companyResults.length" class="text-center pb-5">
                            {{ t('search.noResults') }}
                        </p>
                        <Highlighter
                            v-else
                            v-for="(result, index) in companyResults"
                            :key="`highlight_company_${index}`"
                            class="result"
                            :targetWords="result.name"
                            :searchTerm="searchTerm"
                            :ticker="result.stock_ticker"
                            :company-pk="result.aon_entity_pk"
                            :watchlistPk="props.watchlistPk"
                            :company-params="result"
                            @clear="clearSearch()"
                        />
                    </div>
                </div>
                <!-- Markets -->
                <div v-if="hasMarketsContext" class="market-results">
                    <div class="totals d-flex align-items-center justify-content-between px-2 py-3">
                        <div class="wrap d-flex align-items-center">
                            <font-awesome-icon :icon="`fas fa-globe`" class="grey02--text" />
                            <p class="grey02--text bold ml-2">
                                {{ t('search.markets') }} <span>{{ marketResultsTotal }}</span>
                            </p>
                        </div>
                        <p
                            class="link bold"
                            data-cy="search-all-markets"
                            @click="searchAllPage('markets')"
                        >
                            {{ t('search.search.markets') }}
                        </p>
                    </div>
                    <div class="results">
                        <p v-if="!marketResults.length" class="text-center pb-5">
                            {{ t('search.noResults') }}
                        </p>
                        <Highlighter
                            v-else
                            v-for="(result, index) in marketResults"
                            :key="`highlight_market_${index}`"
                            class="result px-2"
                            :targetWords="result.aon_sector_name"
                            :searchTerm="searchTerm"
                            @click="exploreMarket(result)"
                        />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { debounce } from 'lodash-es'
import * as osApi from '@/api/opensearch'
import { getEntitySearchQuery, getSectorSearchQuery } from '@/lib/openSearchQueryBuilder'

import Highlighter from './helpers/Highlighter'
// TODO use AonMenu instead of doing a one-off custom dropDown

const props = defineProps({
    context: {
        type: String,
        default: 'both',
        validator: (value) => ['companies', 'markets', 'both'].includes(value),
    },
    ignoreEnter: {
        type: Boolean,
        default: false,
    },
    watchlistPk: {
        type: String,
        default: null,
    },
})

const logger = inject('logger')
const eventBus = inject('eventBus')
const router = useRouter()
const { t } = useI18n()

const companyResults = ref([])
const companyResultsTotal = ref(0)
const loadingResults = ref(false)
const marketResults = ref([])
const marketResultsTotal = ref(0)
const searchInput = ref('searchInput')
const searchTerm = ref('')
const showResults = ref(false)

const hasBothContexts = computed(() => {
    return props.context === 'both'
})
const hasCompaniesContext = computed(() => {
    return !!hasBothContexts.value || props.context === 'companies'
})
const hasMarketsContext = computed(() => {
    return !!hasBothContexts.value || props.context === 'markets'
})
const isSearchTermEmpty = computed(() => {
    return !searchTermTrimmed.value
})
const resultsVisible = computed(() => {
    return !loadingResults.value && showResults.value
})
const searchTermTrimmed = computed(() => {
    return searchTerm.value ? searchTerm.value.trim() : searchTerm.value
})

onMounted(async () => {
    await nextTick()
    setFocus()
    eventBus.on('update-watchlist', () => {
        clearSearch()
    })
})

const clearSearch = () => {
    searchTerm.value = ''
    showResults.value = false
    setFocus()
    companyResults.value = []
    companyResultsTotal.value = 0
    marketResults.value = []
    marketResultsTotal.value = 0
    loadingResults.value = false
}
const getScreenerFilter = (context) => {
    if (!searchTermTrimmed.value.length) {
        return
    }

    const filterColumn = context === 'companies' ? 'name' : 'aon_sector_name'

    return btoa(
        JSON.stringify({
            filters: [
                {
                    filterColumn,
                    filterType: 'complex_text',
                    filterLabel: 'Name',
                    filterValue: searchTermTrimmed.value,
                },
            ],
        })
    )
}
const liveSearch = debounce(async () => {
    if (searchTermTrimmed.value.length < 2) {
        return
    }

    // TODO handle async API requests with a fetch API abort(if applicable to the POST search request) OR a cancellationToken(deprecated)
    if (isSearchTermEmpty.value) {
        clearSearch()
        return
    }

    loadingResults.value = true
    await searchCompanies()
    await searchMarkets()
    showResults.value = true

    loadingResults.value = false
}, 750)
const onClick = () => {
    showResults.value = isSearchTermEmpty.value ? false : true
}

const exploreMarket = (market) => {
    router.push({ name: 'Research Market', params: { targetPk: market.aon_sector_pk } })
    clearSearch()
}
const searchCompanies = async () => {
    if (!hasCompaniesContext.value) {
        return
    }

    const params = {
        FV: [searchTermTrimmed.value],
        page_size: 5,
    }

    try {
        const osParams = getEntitySearchQuery(params)
        const response = await osApi.searchEntities(osParams)

        if (response.status !== 200) {
            companyResults.value = []
            companyResultsTotal.value = 0
            throw new Error(`Companies search failure for ${searchTermTrimmed.value}`)
        }

        companyResultsTotal.value = response.data.hits.total.value.toLocaleString()
        companyResults.value = response.data.hits.hits.map((src) => src._source)
    } catch (err) {
        logger.error(err)
    }
}
const searchMarkets = async () => {
    if (!hasMarketsContext.value) {
        return
    }

    const params = {
        FV: [searchTermTrimmed.value],
        page_size: 5,
    }

    try {
        const osParams = getSectorSearchQuery(params)
        const response = await osApi.searchSectors(osParams)

        if (response.status !== 200) {
            marketResults.value = []
            marketResultsTotal.value = 0
            throw new Error(`Markets search failure for ${searchTermTrimmed.value}`)
        }

        marketResultsTotal.value = response.data.hits.total.value.toLocaleString()
        marketResults.value = response.data.hits.hits.map((src) => src._source)
    } catch (err) {
        logger.error(err)
    }
}
const searchAllPage = async (context) => {
    if (props.ignoreEnter && !context) {
        return
    }

    if (!searchTermTrimmed.value.length && !context) {
        return
    }
    let correctContext = context

    if (!context) {
        correctContext = props.context === 'markets' ? 'markets' : 'companies'
    }

    const filter = getScreenerFilter(correctContext)
    const query = filter ? { filter } : null

    await router.push({
        path: `/${correctContext}`,
        query,
    })
}
const setFocus = () => {
    if (searchInput.value) {
        searchInput.value.setFocus()
    }
}
</script>

<style lang="scss">
.global-search {
    position: relative;

    &.alt-style {
        width: 900px;
    }

    .dropdown-results {
        position: absolute;
        top: 43px;
        left: 0;
        z-index: 2;

        &.alt-style {
            top: 78px;
        }

        &.add-border {
            border-top: solid 1px $grey01;
            border-radius: 0 0 4px 4px;
        }

        .totals {
            &:nth-of-type(1) {
                border-top: solid 1px grey;
            }
        }
    }

    .result {
        transition: all ease-in-out 0.3s;
        cursor: pointer;
    }
}
</style>
