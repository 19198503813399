<template>
    <div class="funding-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard class="funding-card">
                        <div class="card-content">
                            <AonContainer class="mb-10">
                                <AonRow>
                                    <AonCol class="aon-col-12 pa-0 mb-5">
                                        <SymbolBanner />
                                    </AonCol>
                                </AonRow>
                                <AonRow v-if="!props.headerOnly">
                                    <AonCol class="aon-col-12 pa-0 mb-5">
                                        <FundamentalData />
                                    </AonCol>
                                </AonRow>
                                <AonRow v-if="!props.headerOnly">
                                    <AonCol class="aon-col-8 py-0 pl-0">
                                        <AdvancedChart />
                                    </AonCol>
                                    <AonCol class="aon-col-4 py-0 pr-0">
                                        <TopStories />
                                    </AonCol>
                                </AonRow>
                            </AonContainer>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import SymbolBanner from '@/components/widgets/financial/SymbolBanner.vue'
import FundamentalData from '@/components/widgets/financial/FundamentalData.vue'
import AdvancedChart from '@/components/widgets/financial/AdvancedChart.vue'
import TopStories from '@/components/widgets/financial/TopStories.vue'

const props = defineProps({
    headerOnly: {
        type: Boolean,
        default: false,
    },
})
</script>

<style lang="scss" scoped>
.funding-card {
    width: 100%;
    position: relative;
}
</style>
