<template>
    <div class="arena-board-edit-modal" v-if="arenaStore.showEditModal">
        <AonModal
            title="Edit the name of your arena board"
            message=""
            col-width="4"
            @close="arenaStore.showEditModal = false"
        >
            <div class="modal-content">
                <form @submit.prevent="updateArena()">
                    <AonInput
                        v-model="form.name"
                        name="name"
                        label="Arena Name"
                        id="name"
                        focus
                        :error="!!v$.name.$errors.length"
                        :error-options="{
                            errorMsg: v$.name.$errors[0] ? v$.name.$errors[0].$message : null,
                        }"
                        @keydown.enter.prevent="updateArena()"
                    />
                    <AonButton
                        class="mt-4"
                        label="Submit"
                        :loading="arenaUpdateLoading"
                        is-form-submit
                    />
                </form>
                <!-- <div class="button-holder w-full d-flex mt-2">
                    <AonButton
                        class="mr-5"
                        label="Continue"
                        :loading="arenaUpdateLoading"
                        @clicked="updateArena()"
                    />
                    <AonButton type="subtle" label="View Report" @clicked="goToReportOutput()" />
                </div> -->
            </div>
        </AonModal>
    </div>
</template>

<script setup>
import { inject, ref, reactive, onBeforeUnmount, watch } from 'vue'
import { patchArena } from '@/api/arena.js'
import { useArenaStore } from '@/stores'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

const emit = defineEmits(['update-arena'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const arenaStore = useArenaStore()

const props = defineProps({
    productAlignmentPkOverwrite: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: '',
    },
})

const error = ref(null)
const arenaUpdateLoading = ref(false)

const form = reactive({
    name: '',
})
const rules = {
    name: { required, maxLength: maxLength(100) },
}
const v$ = useVuelidate(rules, form)

watch(
    () => props.name,
    async (newVal, oldVal) => {
        form.name = ''
        form.name = newVal
    }
)

onBeforeUnmount(() => {
    form.name = ''
})

const updateArena = async () => {
    arenaUpdateLoading.value = true

    const result = await v$.value.name.$validate()

    if (!result) {
        arenaUpdateLoading.value = false
        return
    }

    const params = {
        name: form.name,
    }

    try {
        const productAlignmentPk = props.productAlignmentPkOverwrite
            ? props.productAlignmentPkOverwrite
            : arenaStore.boardGlobalDetails.productAlignmentId
        const { data } = await patchArena(productAlignmentPk, params)
        if (arenaStore.boardGlobalDetails) {
            arenaStore.boardGlobalDetails.name = data.name
        }
    } catch (err) {
        handleError(err)
    } finally {
        arenaUpdateLoading.value = false
        arenaStore.showEditModal = false
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
.arena-board-view {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('/grid.png');

    .get-started {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        text-transform: uppercase;
    }

    .report-complete-cover {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    :deep(.aon-radio-group) {
        .radio-container {
            flex-wrap: wrap;

            .radio-button {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
