<template>
    <div
        class="company-details d-flex flex-column justify-content-start"
        :class="{ collapsed: collapseHeader }"
    >
        <AonAlertBox v-if="error" class="mx-auto" type="error" :title="error" />
        <AonContainer add-page-padding class="header-holder py-5 ma-0">
            <AonRow>
                <AonCol class="aon-col-12 pa-0" justify-content="space-between">
                    <CompanyHeader
                        :loading="!entityStore.entity || entityStore.loadingEntity"
                        :entity="entityStore.entity"
                    />
                </AonCol>
            </AonRow>
        </AonContainer>
        <AonContainer add-page-padding class="below-header tab-holder ma-0">
            <div class="background-helper" />
            <AonRow>
                <AonCol class="aon-col-12 pa-0" justify-content="space-between">
                    <AonTabs
                        :key="tabUpdater"
                        class="details pt-2"
                        :tabs="tabData"
                        :current-tab="currentTab"
                        @tab-change="tabChange"
                    />
                </AonCol>
            </AonRow>
        </AonContainer>

        <KeepAlive>
            <Profile v-if="currentTab === 1" class="h-full" />
        </KeepAlive>

        <KeepAlive>
            <IPBasicsTab v-if="currentTab === 2" class="h-full" />
        </KeepAlive>

        <div v-show="currentTab === 3" class="h-full">
            <AonSpinner
                v-if="entityStore.loadingEntity"
                class="ma-auto"
                :scale="0.5"
                color="castleMoat"
            />
            <Moat2ProductTab v-else />
        </div>

        <KeepAlive>
            <VulnerabilitiesTab v-if="currentTab === 4" class="h-full" />
        </KeepAlive>

        <KeepAlive>
            <ExecutionTab v-if="currentTab === 5" class="h-full" />
        </KeepAlive>

        <KeepAlive>
            <ValuationsTab v-if="currentTab === 6" class="h-full" />
        </KeepAlive>

        <KeepAlive>
            <InnovationTab
                v-if="currentTab === 7"
                :entityId="parseInt(entityStore?.entity?.aon_entity_pk)"
                class="h-full"
            />
        </KeepAlive>

        <TechnologyDrillInModal v-if="entityStore.showDrillIn" />
    </div>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useParticipatingMarketsStore, useEntityStore } from '@/stores'
import { useRouter } from 'vue-router'
import { throttle } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import CompanyHeader from './CompanyHeader'
import Profile from '@/components/company/tabs/profile/ProfileTab.vue'
import IPBasicsTab from '@/components/company/tabs/IPBasics/IPBasicsTab.vue'
import Moat2ProductTab from '@/components/company/tabs/moat2Product/Moat2ProductTab.vue'
import VulnerabilitiesTab from '@/components/company/tabs/vulnerabilities/VulnerabilitiesTab.vue'
import InnovationTab from '@/components/company/tabs/innovation/InnovationTab.vue'
import ExecutionTab from '@/components/company/tabs/execution/ExecutionTab.vue'
import ValuationsTab from '@/components/company/tabs/valuations/ValuationsTab.vue'

import TechnologyDrillInModal from '@/components/company/drillIn/TechnologyDrillInModal.vue'

const props = defineProps({
    entityId: {
        type: String,
        required: true,
    },
})
const router = useRouter()
const logger = inject('logger')
const { t } = useI18n()
const showVulTab = useFlag('alpha.vulnerabilitiesTab')
const showInnovationTab = useFlag('alpha.innovationTab')
const participatingMarketsStore = useParticipatingMarketsStore()
const entityStore = useEntityStore()

const collapseHeader = ref(false)
const error = ref(false)
const tabUpdater = ref(0)

watch(
    () => entityStore.entity,
    async (newVal, oldVal) => {
        if (newVal && newVal?.aon_entity_pk !== oldVal?.aon_entity_pk) {
            tabUpdater.value++
        }
    }
)

const tabData = computed(() => {
    let finalTabList = []
    finalTabList.push(
        {
            text: 'Profile',
            value: 'profile',
            id: 1,
        },
        {
            text: 'IP Basics',
            value: 'ipBasics',
            id: 2,
        },
        {
            text: 'Moat2Product',
            value: 'moat2Product',
            id: 3,
        },
        {
            text: 'Vulnerabilities',
            value: 'vulnerabilities',
            id: 4,
            disabled: !showVulTab.value,
        },
        {
            text: 'Execution',
            value: 'execution',
            id: 5,
        },
        {
            text: 'Valuations',
            value: 'value',
            id: 6,
            // disabled: disableTab.value,
            // disabledText: 'This company doesn\'t have any markets associated with it',
        }
    )
    if (showInnovationTab.value) {
        finalTabList.push({
            text: 'Innovation',
            value: 'innovation',
            id: 7,
        })
    }
    return finalTabList
})

const currentTab = computed(() => {
    let tabName = router.currentRoute.value.query.tabName
    if (tabName === 'profile') {
        return 1
    } else if (tabName === 'ipBasics') {
        return 2
    } else if (tabName === 'moat2Product') {
        return 3
    } else if (tabName === 'vulnerabilities') {
        return 4
    } else if (tabName === 'execution') {
        return 5
    } else if (tabName === 'value') {
        return 6
    } else if (tabName === 'innovation') {
        return 7
    } else {
        return 1
    }
})

const disableTab = computed(() => {
    return participatingMarketsStore.competenciesMap.length === 0 || entityStore.loadingMarkets
})

onMounted(() => {
    getEntity(router.currentRoute.value.params.targetPk)
})

onUnmounted(() => {
    entityStore.resetState()
})

const getEntity = async (entityPk) => {
    participatingMarketsStore.resetState()
    entityStore.loadingEntity = true

    try {
        await entityStore.getEntity(entityPk)
    } catch (err) {
        logger.error(err)

        const errorType = err?.response?.status === 404 ? 'notFound' : 'general'

        error.value = t(`entityDetails.errors.${errorType}`)
    } finally {
        entityStore.loadingEntity = false
        entityStore.getPatentBreakdown(entityPk)
    }
}

const tabChange = (val) => {
    router.push({
        name: 'Research Company',
        params: { targetPk: entityStore.entity.aon_entity_pk },
        query: { tabName: val.value },
    })
}
</script>

<style lang="scss" scoped>
@import '@moatmetrics/armory/src/sass/responsive.scss';

.company-details {
    height: 100%;
    min-height: 100vh;
    .header-holder {
        background: $knightsCloak80;
    }

    .tab-holder {
        background: $knightsCloak60;
    }

    .background-helper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
</style>
