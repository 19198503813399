<template>
    <div class="innovation-trend-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="Innovation Trend"
                        desc="Visualize the dynamic movement of inventors into and out of the company annually. This chart helps you gauge the health and vibrancy of innovation, highlighting trends in talent acquisition and retention."
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loadingTrend"
                                title="Loading Innovation Trend..."
                            ></AonCoverLoading>
                            <div id="amChartInnovation"></div>
                            <p class="y-axis-label bold">Net Inventor Tenure</p>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import {
    onMounted,
    onBeforeUnmount,
    ref,
    shallowRef,
    inject,
    onActivated,
    onDeactivated,
} from 'vue'
import { getInnovationTrend } from '@/api/companytabs/execution.js'
import { useEntityStore } from '@/stores'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useRoute } from 'vue-router'
import { config } from '@/config'

const route = useRoute()
const entityId = route.params.targetPk
const entityStore = useEntityStore()
const logger = inject('logger')

let root
let yAxis
let xAxis

const loadingTrend = ref(true)
const chartData = shallowRef(null)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartInnovation')
    configureData()
})

onBeforeUnmount(() => {
    root.dispose()
})

const configureData = async () => {
    try {
        const { data } = await getInnovationTrend(entityId)
        chartData.value = data.map((item) => {
            return {
                year: item.year.toString(),
                value: item.netInventors,
            }
        })
    } catch (err) {
        logger.error(err)
    } finally {
        configureChart()
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panY: false,
            interactive: false,
            wheelY: 'none',
            layout: root.verticalLayout,
            paddingLeft: 30,
        })
    )
    generateAxes(chart)
}

const generateAxes = (chart) => {
    var xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 60,
    })
    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    xRenderer.grid.template.setAll({
        location: 1,
    })

    xAxis.data.setAll(chartData.value)

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0,
            }),
        })
    )

    generateSeries(chart)
}

const generateSeries = (chart) => {
    var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            sequencedInterpolation: true,
            valueYField: 'value',
            categoryXField: 'year',
            stroke: am5.color(0xffffff),
        })
    )

    series.columns.template.setAll({
        tooltipText: '[bold]{categoryX}:[/] {valueY}',
        width: am5.percent(90),
        tooltipY: 0,
        strokeOpacity: 0,
    })

    series.columns.template.adapters.add('fill', function (fill, target) {
        if (target.dataItem.get('valueY') < 0) {
            return am5.color('#E06259') // error
        } else {
            return am5.color('#143862') //knights-cloak
        }
    })

    series.data.setAll(chartData.value)

    let legend = chart.children.push(
        am5.Legend.new(root, {
            nameField: 'name',
            fillField: 'color',
            strokeField: 'color',
            centerX: am5.percent(30),
            x: am5.percent(50),
            paddingTop: 20,
        })
    )

    legend.data.setAll([
        {
            name: 'Inventors In',
            color: am5.color('#143862'), // Knights-cloak
        },
        {
            name: 'Inventors Out',
            color: am5.color('#E06259'), // error
        },
    ])

    series.appear()
    loadingTrend.value = false
}
</script>

<style lang="scss" scoped>
.innovation-trend-card {
    width: 100%;
    position: relative;

    #amChartInnovation {
        width: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }

    .card-content {
        position: relative;
    }

    .y-axis-label {
        position: absolute;
        left: 10px;
        top: 60%;
        transform-origin: center left;
        transform: translateY(-50%) rotate(-90deg);
    }
}
</style>
