<template>
    <form class="screenerCreateForm">
        <AonInput
            v-model="formScreener.name"
            class="mb-4"
            :label="$t('screeners.screener.screenerForm.name')"
        />
        <AonInput
            v-model="formScreener.description"
            class="mb-4"
            :label="$t('screeners.screener.screenerForm.description')"
        />
        <AonButton
            class="mr-4"
            :disabled="submitDisabled"
            :label="$t(`screeners.screener.actions.buttons.${submitText}`)"
            :loading="loading"
            @clicked="handleSubmit()"
        />
    </form>
</template>

<script>
import { mapState } from 'pinia'
import { useAuth } from '@/auth/authPlugin'

export default {
    name: 'ScreenerForm',
    components: {},
    props: {
        screener: {
            type: Object,
            required: false,
            default: null,
        },
        queryFilters: {
            type: [Object, String],
            required: false,
            default: null,
        },
        submitText: {
            type: String,
            required: false,
            default: 'save',
        },
        icon: {
            type: String,
            default: 'fas fa-plus',
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            formScreener: {
                name: '',
                description: '',
                ownedByUserId: null,
                companyId: null,
                customScreener: true,
                sharedWithOthers: false,
                type: null,
                query: null,
            },
        }
    },
    computed: {
        user() {
            const { user } = useAuth()

            return user
        },
        submitDisabled() {
            return !this.formScreener.name.length || !this.formScreener.description.length
        },
    },
    created() {
        //rename
        if (this.screener && Object.keys(this.screener).length !== 0) {
            this.formScreener.name = this.screener.name
            this.formScreener.description = this.screener.description
            this.formScreener.id = this.screener.id
            this.formScreener.query = this.screener.query
        }

        this.formScreener.type =
            this.$router.currentRoute.value.name === 'Companies' ? 'COMPANY' : 'MARKET'
        this.formScreener.ownedByUserId = this.user.value.id
        this.formScreener.companyId = this.user.value.org_id
        //save as
        if (this.queryFilters) {
            this.formScreener.query = this.queryFilters
        }
    },
    methods: {
        handleSubmit() {
            this.$emit('submit', this.formScreener)
        },
    },
}
</script>

<style scoped></style>
