<template>
    <div class="select-target-company">
        <h5>Select Target Company</h5>
        <AonCard class="mt-5">
            <div class="content-holder" style="display: flex; flex-direction: column; height: 100%">
                <div class="header mb-5">
                    <AonInput
                        style="width: 500px"
                        v-model="searchTerm"
                        search-input
                        label="Search Contender Table"
                        placeholder="Search Contender Table"
                        @keyup="searchTable()"
                        @clear="clearSearch()"
                    />
                </div>
                <MoatTable
                    style="width: 100%; flex-grow: 1"
                    :defaultColDef="defaultColDef"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :row-data="tableData"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    :autoSizeStrategy="sizingStrategy"
                    row-model-type="serverSide"
                    :cache-block-size="50"
                    :max-blocks-in-cache="10"
                    @grid-ready="gridInit"
                    @row-selected="setNodeToAdd"
                />
                <AonCoverLoading
                    v-if="companyLockedIn"
                    :loading="companyLockedIn ? true : false"
                    :show-spinner="false"
                    :title="`Target Company '${companyLockedIn.selectionLabel}' has already been selected`"
                    message="Changes to the target company cannot be edited at this time."
                >
                    <template #header>
                        <div class="icon">
                            <font-awesome-icon icon="fas fa-lock" class="grey01--text" size="2xl" />
                        </div>
                    </template>
                </AonCoverLoading>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, nextTick, inject, computed } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'
import { getEntitySearchQuery, getOpenSearchQuery } from '@/lib/openSearchQueryBuilder'
import * as osApi from '@/api/opensearch'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { moatParamBuilder } from '@/components/moatTable/helpers/queryBuilder.js'

const logger = inject('logger')
const filters = inject('filters')

const reportGenerationStore = useReportGenerationStore()

const companySelected = ref(null)
// Table
const searchTerm = ref('')
const gridApi = ref(null)
const tableData = ref([])
const tablePageSize = ref(50)
const tablePageNum = ref(0)
const defaultColDef = ref({
    sortable: false,
})
const themeClass = ref('ag-theme-quartz')
const sizingStrategy = ref({
    type: 'fitProvidedWidth',
    width: 2400,
})
const colDefs = ref([
    {
        field: 'name',
        checkboxSelection: true,
        cellClass: 'radio-cell',
        headerName: 'Company',
        flex: 3,
        filter: 'agTextColumnFilter',
        filterParams: {
            buttons: ['clear'],
            defaultOption: 'contains',
            filterOptions: ['startsWith', 'equals', 'contains'],
            maxNumConditions: 1,
        },
        headerTooltip: 'Company',
        cellRenderer: 'MTCustomCellCompany',
        cellRendererParams: {
            allowNewTab: true,
            tabOnLeft: true,
        },
        cellClassRules: {
            'disabled-checkbox': (params) => {
                return companySelected.value
            },
        },
    },
    {
        field: 'hq_country_name',
        headerName: 'HQ Country',
        flex: 1,
    },
    {
        field: 'ultimate_aon_entity_name',
        headerName: 'Ultimate Parent',
        flex: 1,
    },
    {
        field: 'employee_count',
        headerName: 'Employee Count',
        flex: 1,
    },
    {
        field: 'total_revenue',
        headerName: 'Annual Revenue',
        flex: 1,
    },
    {
        field: 'patent_count_us',
        headerName: 'Active Assets',
        flex: 1,
    },
    {
        field: 'ownership_status',
        headerName: 'Ownership Status',
        flex: 1,
    },
    {
        field: 'homepage_url',
        headerName: 'URL',
        flex: 2,
    },
])

onMounted(async () => {
    await nextTick()
})

const companyLockedIn = computed(() => {
    if (reportGenerationStore.lockCompanySelection) {
        return reportGenerationStore.reportSelections
            ? reportGenerationStore.reportSelections[1]
            : false
    } else {
        return false
    }
})

const gridInit = (params) => {
    gridApi.value = params.api
    setupServerSideData()
    gridApi.value.closeToolPanel()
}

const setupServerSideData = async () => {
    const datasource = createServerSideDatasource()
    gridApi.value.setGridOption('serverSideDatasource', datasource)
}

const fetchCompetitorData = async (paramsFromTable) => {
    try {
        let finalParams = moatParamBuilder(
            paramsFromTable,
            { page_size: tablePageSize.value, last_row_num: 0, page: tablePageNum.value },
            null
        )
        const osParams = getEntitySearchQuery(finalParams)
        const response = await osApi.searchEntities(osParams)

        let scrubbedData = response.data.hits.hits.map((src) => {
            const item = src._source

            return {
                ...item,
                employee_count: filters.toLocaleString(item.employee_count),
                patent_count_us: filters.toLocaleString(parseInt(item.patent_count_us)),
                total_revenue: filters.abbreviate(item.total_revenue),
            }
        })

        tablePageNum.value++
        return {
            success: true,
            rows: scrubbedData,
        }
    } catch (err) {
        logger.error(err)
        return {
            success: false,
        }
    }
}

const createServerSideDatasource = () => {
    return {
        getRows: async (params) => {
            const response = await fetchCompetitorData(params.request)

            if (response.success) {
                params.success({ rowData: response.rows })
            } else {
                params.fail()
            }
        },
    }
}

const setNodeToAdd = (row) => {
    companySelected.value = row.node.data
    reportGenerationStore.setReportCompanySelection(companySelected.value)
}

// search filter
const searchTable = async () => {
    await gridApi.value.setColumnFilterModel('name', {
        filterType: 'text',
        type: 'startsWith',
        filter: searchTerm.value,
    })

    // Tell grid to run filter operation again
    gridApi.value.onFilterChanged()
}

const clearSearch = () => {
    searchTerm.value = ''
    gridApi.value.setFilterModel(null)
}
</script>

<style lang="scss" scoped>
.select-target-company {
    height: 95%;
    width: 100%;
}
</style>
