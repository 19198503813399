<template>
    <div class="executive-team-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="Executive Team"
                        desc="Discover the leaders at the helm of the company."
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loadingExecutives"
                                title="Loading Executive Team..."
                            ></AonCoverLoading>
                            <MoatTable
                                style="width: 100%; height: 400px"
                                :class="themeClass"
                                :column-defs="colDefs"
                                :suppress-auto-size="true"
                                :row-data="tableData"
                                @grid-ready="gridInit"
                            />
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, inject, onMounted } from 'vue'
import { useEntityStore } from '@/stores'

import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { dateRangeComparator } from '@/components/moatTable/helpers/compare.js'
import { useRoute } from 'vue-router'

const route = useRoute()
const entityId = route.params.targetPk
const filters = inject('filters')
const logger = inject('logger')
const entityStore = useEntityStore()

const loadingExecutives = ref(true)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'title',
        flex: 1,
    },
    {
        field: 'name',
        flex: 1,
    },
    {
        headerName: 'Tenure',
        flex: 1,
        valueGetter: (p) => {
            if (p.data) {
                return `${[p.data.startDate, p.data.endDate]}`
            }
        },
        valueFormatter: (p) => {
            if (p.data) {
                let start = filters.toUTCString(p.data.startDate)
                let end = filters.toUTCString(p.data.endDate)

                if (start === '1-1-1') {
                    start = 'Unknown'
                }
                if (end === '1-1-1') {
                    end = 'Present'
                }
                return `${start} - ${end}`
            }
        },
        comparator: dateRangeComparator,
    },
])

const tableData = ref([])

onMounted(() => {
    fetchExecutiveTeamData()
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const fetchExecutiveTeamData = async () => {
    loadingExecutives.value = true
    try {
        const data = await entityStore.getExecutiveTeam(entityId)
        tableData.value = data.results
    } catch (err) {
        logger.error(err)
    } finally {
        loadingExecutives.value = false
    }
}
</script>

<style lang="scss" scoped>
.executive-team-card {
    width: 100%;
    position: relative;
}
</style>
