<template>
    <div class="knowledge-exchange-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="Knowledge Exchange"
                        desc="Discover how knowledge travels through our network of inventors. This section maps the paths of expertise brought into and taken out of the company."
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loadingExchange"
                                title="Loading Knowledge Exchange..."
                            ></AonCoverLoading>
                            <div id="amChartKnowledge"></div>
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, shallowRef } from 'vue'
import { useEntityStore } from '@/stores'
import { getPatentFlow } from '@/api/companytabs/execution.js'

import * as am5 from '@amcharts/amcharts5'
import * as am5flow from '@amcharts/amcharts5/flow'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { fill } from 'lodash-es'
import { config } from '@/config'

const entityStore = useEntityStore()

let root
const loadingExchange = ref(true)
const chartData = shallowRef(null)

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartKnowledge')
    configureData()
})

onBeforeUnmount(() => {
    root.dispose()
})
const configureData = async () => {
    try {
        const { data } = await getPatentFlow(entityStore.entity.aon_entity_pk)
        const entityName = entityStore.entity.name
        const seenNames = new Set()

        // Use a single Set to track unique names across 'from' and 'to' params
        // Append ' ' or ' ' to repeat names that don't match entityName
        let baseDataMapped = data.map((item) => {
            let from = item.timeline === 'after' ? entityName : item.aonEntityName
            let to = item.timeline === 'before' ? entityName : item.aonEntityName

            if (from !== entityName && seenNames.has(from)) {
                from = ` ${from}`
            }
            seenNames.add(from)

            if (to !== entityName && seenNames.has(to)) {
                to = ` ${to}`
            }
            seenNames.add(to)

            return {
                from,
                to,
                value: item.patentCount,
            }
        })

        baseDataMapped = baseDataMapped.filter((item) => item.from !== item.to)
        chartData.value = baseDataMapped
    } catch (err) {
        logger.error(err)
    } finally {
        configureChart()
    }
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    // Create series
    var series = root.container.children.push(
        am5flow.Sankey.new(root, {
            sourceIdField: 'from',
            targetIdField: 'to',
            valueField: 'value',
            nodeAlign: 'left',
            nodePadding: 16,
            nodeWidth: 10,
            tooltipPosition: 'pointer',
        })
    )

    setupNodeConfig(series)
    setupLinkConfig(series)

    // Set data
    series.data.setAll(chartData.value)
    loadingExchange.value = false
}

const setupNodeConfig = (series) => {
    // Set node labels
    series.nodes.labels.template.setAll({
        x: am5.p50,
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: 'bold',
        fontSize: 16,
        text: '{name}',
    })

    // Flips the label to the other side for nodes with only incoming links
    series.nodes.labels.template.adapters.add('centerX', function (center, target) {
        if (target.dataItem.get('incomingLinks', []) == 0) {
            return am5.p0
        } else if (target.dataItem.get('outgoingLinks', []) == 0) {
            return am5.p100
        }
        return am5.p0
    })

    // Set node shape
    series.nodes.rectangles.template.setAll({
        cornerRadiusTL: 2,
        cornerRadiusTR: 2,
        cornerRadiusBL: 2,
        cornerRadiusBR: 2,
        stroke: am5.color(0x000000),
        strokeWidth: 2,
    })
}

const setupLinkConfig = (series) => {
    // Link coloring
    series.links.template.setAll({
        fillStyle: 'solid',
    })
}
</script>

<style lang="scss" scoped>
.knowledge-exchange-card {
    width: 100%;
    position: relative;

    #amChartKnowledge {
        width: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }
}
</style>
