<template>
    <div class="return-on-innovation">
        <AonCoverLoading
            :loading="loadingData"
            title="Loading Return on Innovation..."
        ></AonCoverLoading>
        <AonCoverLoading
            v-if="noData"
            :loading="noData ? true : false"
            :show-spinner="false"
            title="No Return on Innovation Identified"
        >
            <template #header>
                <div class="icon">
                    <font-awesome-icon
                        icon="fas fa-circle-exclamation"
                        class="grey01--text"
                        size="2xl"
                    />
                </div>
            </template>
        </AonCoverLoading>
        <div id="amChartReturnOnInnovation"></div>
        <AonDropdown
            class="option-dropdown"
            :class="{ disabled: loadingData }"
            :items="chartOptions"
            label=""
            :init-selection="chartOptions[0]"
            @select-option="selectInnovationType"
        />
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, inject, watch } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { getReturnOnInnvoation } from '@/api/productAlignment.js'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const logger = inject('logger')
const moat2ProductStore = useMoat2ProductStore()
const entityStore = useEntityStore()

const loadingData = ref(true)
const noData = ref(false)
const allData = ref(null)
const targetData = ref(null)
const selectedInnovation = ref('Cor')

const chartOptions = ref([
    {
        itemText: 'Moat Quality',
        value: 'Cor',
    },
    {
        itemText: 'Innovation Implied P/E',
        value: 'Tpe',
    },
])

let root
let chart
let yAxis
let xAxis
let targetSeries
let benchmarkSeries
let differenceSeries

onMounted(() => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartReturnOnInnovation')
    loadingData.value = true
})

onBeforeUnmount(() => {
    root.dispose()
})

watch(
    () => moat2ProductStore.topCompetitorCollection,
    (newVal, oldVal) => {
        if (newVal !== oldVal) {
            getAndMapData()
        }
    },
    { once: true }
)

const getAndMapData = async () => {
    loadingData.value = true
    try {
        let { data } = await getReturnOnInnvoation(
            moat2ProductStore.productAlignmentId,
            moat2ProductStore.topCompetitorCollection
        )
        allData.value = data
        setTargetData()
    } catch (error) {
        logger.error(error)
    } finally {
        loadingData.value = false
    }
}

const setTargetData = () => {
    targetData.value = allData.value.map((obj) => {
        return {
            year: obj.year,
            target: obj[`target${selectedInnovation.value}`],
            benchmark: obj[`benchmark${selectedInnovation.value}`],
            percentChange:
                obj[`percentChange${selectedInnovation.value}`] > -1 &&
                obj[`percentChange${selectedInnovation.value}`] < 1
                    ? 0
                    : obj[`percentChange${selectedInnovation.value}`],
        }
    })
    configureChart()
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])
    root.numberFormatter.setAll({
        numberFormat: '#.',
    })

    // Create chart
    chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'none',
            wheelY: 'none',
            paddingTop: 20,
            paddingBottom: 50,
        })
    )

    chart.zoomOutButton.set('forceHidden', true)

    generateAxes()
}

const generateAxes = () => {
    let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        minGridDistance: 30,
        minorGridEnabled: true,
        inversed: true,
    })

    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'year',
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    xRenderer.grid.template.setAll({
        location: 1,
    })

    xAxis.data.setAll(targetData.value)

    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            extraMax: 0.1,
            renderer: am5xy.AxisRendererY.new(root, {
                strokeOpacity: 0.1,
            }),
        })
    )

    generateSeries()
}

const generateSeries = () => {
    // Variance indicator series // arrow
    differenceSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'target', // close
            openValueYField: 'benchmark', // start
            categoryXField: 'year',
            fill: am5.color('#666666'),
            stroke: am5.color('#666666'),
        })
    )

    differenceSeries.columns.template.setAll({
        width: 2,
    })

    differenceSeries.data.setAll(targetData.value)

    differenceSeries.bullets.push(() => {
        let label = am5.Label.new(root, {
            text: '{valueY}',
            fontWeight: '500',
            fill: am5.color(0x00cc00),
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true,
        })

        // Modify text of the bullet with percent
        label.adapters.add('text', (text, target) => {
            var percent = getVariancePercent(target.dataItem)
            return percent === 0 ? 'N/A' : percent + '%'
        })

        label.adapters.add('centerY', (center, target) => {
            return getVariancePercent(target.dataItem) < 0 ? 0 : center
        })

        // Set dynamic color of the bullet
        label.adapters.add('fill', (fill, target) => {
            var percent = getVariancePercent(target.dataItem)
            if (percent === 0) return am5.color('#666')
            return percent < 0 ? am5.color(0xcc0000) : fill
        })

        return am5.Bullet.new(root, {
            locationY: 1,
            sprite: label,
        })
    })

    differenceSeries.bullets.push(() => {
        let arrow = am5.Graphics.new(root, {
            rotation: -90,
            centerX: am5.p50,
            centerY: am5.p50,
            dy: 3,
            fill: am5.color('#666666'),
            stroke: am5.color('#666666'),
            draw: (display) => {
                display.moveTo(0, -3)
                display.lineTo(8, 0)
                display.lineTo(0, 3)
                display.lineTo(0, -3)
            },
        })

        arrow.adapters.add('rotation', (rotation, target) => {
            return getVariancePercent(target.dataItem) < 0 ? 90 : rotation
        })

        arrow.adapters.add('dy', (dy, target) => {
            return getVariancePercent(target.dataItem) < 0 ? -3 : dy
        })

        // Hide arrow if percentage is 0
        arrow.adapters.add('visible', (visible, target) => {
            return getVariancePercent(target.dataItem) !== 0
        })

        return am5.Bullet.new(root, {
            locationY: 1,
            sprite: arrow,
        })
    })

    benchmarkSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'benchmark',
            categoryXField: 'year',
        })
    )

    benchmarkSeries.columns.template.setAll({
        tooltipText: '{categoryX}: {valueY}',
        width: am5.percent(80),
        fill: am5.color('#BCC9DF'),
        stroke: am5.color('#BCC9DF'),
        tooltipY: 0,
    })

    benchmarkSeries.data.setAll(targetData.value)

    targetSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'target',
            categoryXField: 'year',
        })
    )

    targetSeries.columns.template.setAll({
        tooltipText: '{categoryX}: {valueY}',
        width: am5.percent(80),
        fill: am5.color('#2A79D2'),
        stroke: am5.color('#2A79D2'),
        tooltipY: 0,
    })

    targetSeries.data.setAll(targetData.value)

    generateLegend()

    targetSeries.appear()
    benchmarkSeries.appear()
    differenceSeries.appear()
    chart.appear(1000, 100)
}

const generateLegend = () => {
    let legend = chart.children.push(
        am5.Legend.new(root, {
            nameField: 'name',
            fillField: 'color',
            strokeField: 'color',
            centerX: am5.percent(40),
            x: am5.percent(50),
            y: am5.percent(100),
            paddingTop: 15,
        })
    )

    legend.data.setAll([
        {
            name: `${entityStore.entity.name}`,
            color: am5.color('#2A79D2'), // castle moat
        },
        {
            name: 'Benchmark Peers',
            color: am5.color('#BCC9DF'), // grey04
        },
        {
            name: 'Variance',
            color: am5.color('#666'), // dark grey
        },
    ])
}

const getVariancePercent = (dataItem) => {
    if (dataItem) {
        let value = dataItem.get('valueY')
        let openValue = dataItem.get('openValueY')
        let change = value - openValue
        return Math.round((change / openValue) * 100)
    }
    return 0
}
const selectInnovationType = (type) => {
    selectedInnovation.value = type.value
    chart.dispose()
    setTargetData()
}
</script>

<style lang="scss" scoped>
.return-on-innovation {
    width: 100%;
    position: relative;

    .option-dropdown {
        position: absolute;
        top: -55px;
        right: 0;
        width: 250px !important;
    }

    #amChartReturnOnInnovation {
        width: 100%;
        height: 600px;

        div {
            height: 100%;
        }
    }
}
</style>
