import { reactive, inject, onMounted } from 'vue'

export default {
    template: `
        <div class="link-wrapper h-full d-flex align-items-center" style="width: fit-content">
            <p class="small overflow-ellipsis" :class="{ 'link': checkForValidType(params.data.type) }">{{params.value}} </p>
        </div>
    `,

    emits: ['cell-clicked'],
    setup(props, { emit }) {
        const state = reactive({
            context: {},
        })

        return {
            state,
        }
    },
}
