<template>
    <div class="top-inventors-card">
        <AonContainer add-page-padding>
            <AonRow class="mt-6">
                <AonCol class="aon-col-12 pa-0 flex-column justify-content-center">
                    <AonCard
                        title="Top Inventors - Last 20 Years"
                        desc="Discover the trailblazers behind the company’s most innovative patents."
                    >
                        <div class="card-content">
                            <AonCoverLoading
                                :loading="loadingInventors"
                                title="Updating Inventor Timeline..."
                            ></AonCoverLoading>
                            <MoatTable
                                style="width: 100%; height: 471px"
                                :class="themeClass"
                                :column-defs="colDefs"
                                :suppress-auto-size="true"
                                :row-data="tableData"
                                :sorting-order="['desc', 'asc', null]"
                                @grid-ready="gridInit"
                            />
                            <InventorBehaviorCard
                                v-if="chartData.length > 0"
                                :key="chartUpdater"
                                :chart-data="chartData"
                            />
                        </div>
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, inject, onMounted, onUnmounted } from 'vue'
import { getTopInventors } from '@/api/companytabs/execution.js'
import { useEntityStore } from '@/stores'

import InventorBehaviorCard from './InventorBehaviorCard.vue'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { formatTpeRange } from '@/lib/financial.js'

const filters = inject('filters')
const logger = inject('logger')
const entityStore = useEntityStore()
const eventBus = inject('eventBus')

const loadingInventors = ref(true)
const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'inventorName',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Inventor Name',
            customSortEmitColumn: 'inventorName',
        },
        flex: 1,
        sortingOrder: ['asc', 'desc', null],
    },
    {
        field: 'rank',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Rank',
            customSortEmitColumn: 'rank',
        },
        width: '150px',
        sortingOrder: ['asc', 'desc', null],
    },
    {
        field: 'firstInventionDateAtCompany',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: `First Invention Date at ${entityStore.entity.name}`,
            customSortEmitColumn: 'firstInventionDateAtCompany',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                let start = filters.toUTCString(p.data.firstInventionDateAtCompany)

                if (start === '1-1-1') {
                    start = 'Unknown'
                }

                return `${start}`
            }
        },
        sortingOrder: ['asc', 'desc', null],
    },
    {
        field: 'lastInventionDateAtCompany',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: `Last Invention Date at ${entityStore.entity.name}`,
            customSortEmitColumn: 'lastInventionDateAtCompany',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                let end = filters.toUTCString(p.data.lastInventionDateAtCompany)

                if (end === '1-1-1') {
                    end = 'Present'
                }

                return `${end}`
            }
        },
    },
    {
        field: 'patentCountAtCompany',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: `Total Patents Filed at ${entityStore.entity.name}`,
            customSortEmitColumn: 'patentCountAtCompany',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.patentCountAtCompany.toLocaleString()}`
            }
        },
    },
    {
        field: 'patentCount',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Total Patents',
            customSortEmitColumn: 'patentCount',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.patentCount.toLocaleString()}`
            }
        },
    },
    {
        field: 'inventorTpe',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            headerName: 'Inventor Innovation Implied P/E',
            customSortEmitColumn: 'inventorTpe',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return formatTpeRange(
                    p.data.inventorTpe,
                    p.data.inventorTpeLb,
                    p.data.inventorTpeUb
                )
            }
        },
    },
])

const tableData = ref([])
const chartData = ref([])
const chartUpdater = ref(0)

onMounted(() => {
    featchTopInventorData()

    eventBus.on('sort-requested', async (data) => {
        if (data.direction === 0) {
            featchTopInventorData()
        } else {
            featchTopInventorData(data.column, data.direction)
        }
    })
})

onUnmounted(() => {
    eventBus.off('sort-requested')
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const featchTopInventorData = async (column = 'rank', direction = 1) => {
    loadingInventors.value = true
    try {
        const { data } = await getTopInventors(entityStore.entity.aon_entity_pk, column, direction)
        tableData.value = data.results
        chartData.value = data.results
    } catch (err) {
        logger.error(err)
    } finally {
        loadingInventors.value = false
        chartUpdater.value++
    }
}
</script>

<style lang="scss" scoped>
.top-inventors-card {
    width: 100%;
    position: relative;
}
</style>
