export function defaultNodeStyles(model, iconBuilder) {
    // let calcWidth = model.data.width && model.data.width * 9 < 100 ? 200 : model.data.width * 9
    return {
        ...model,
        data: {
            ...model.data,
            type: 'modelRect-node',
            lodLevels: [],
            keyShape: {
                width: 280,
                height: 50,
                radius: 4,
                shadowColor: 'rgba(0,0,0,0.1)',
                shadowBlur: 20,
                shadowOffsetX: 0,
                shadowOffsetY: 10,
                stroke: '#2A79D2',
                position: 'right',
            },
            labelShape: {
                maxWidth: '280%',
                text: model.data.nodeType.charAt(0).toUpperCase() + model.data.nodeType.slice(1),
                fontFamily: 'Open Sans',
                fontWeight: 'bold',
                fontSize: 10,
                fill: '#82939A',
                textAlign: 'left',
                dy: 0,
            },
            otherShapes: {
                // left rect
                preRect: {
                    show: false,
                },
                // right icon describing the state
                stateIcon: {
                    show: false,
                },
                // text description
                description: {
                    show: true,
                    text: model.data.name,
                    fontSize: 12,
                    offsetX: 0,
                    offsetY: 0,
                    fontFamily: 'Open Sans',
                    fill: '#46535E',
                    maxWidth: '10%',
                },
                // left icon
                logoIcon: {
                    show: true,
                    img: iconBuilder,
                    width: 32,
                    height: 32,
                    offsetX: 0,
                    offsetY: 0,
                },
            },
            anchorPoints: [
                [0.15, 0.4],
                [0.85, 0.4],
            ],
        },
    }
}

export function defaultEdgeStyles() {
    return {
        type: 'cubic-horizontal-edge',
        keyShape: {
            curveOffset: 50,
            curvePosition: 0.8,
            stroke: '#B3B3B3',
            lineWidth: 3,
            lineDash: [10, 5],
            lineJoin: 'round',
            zIndex: 1,
        },
        labelBackgroundShape: {},
    }
}
