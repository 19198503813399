<template>
    <AonModal
        class="create-modal"
        :class="{ alt: type === 'alt' }"
        title="New Screener"
        :col-width="type === 'alt' ? '8' : '6'"
        @close="cancelDialog()"
        @click:outside="cancelDialog()"
    >
        <ScreenerForm
            :screener="screener"
            :query-filters="queryFilters"
            :loading="loading"
            icon="fas fa-plus"
            @submit="saveScreener"
        />
    </AonModal>
</template>

<script>
import { useScreenerStore } from '@/stores'

import { createScreener as PostScreener } from '@/api/screeners'
import ScreenerForm from '@/components/screeners/ScreenerForm'

export default {
    name: 'ScreenerCreateCard',
    components: { ScreenerForm },
    props: {
        screener: {
            type: Object,
            required: false,
            default: null,
        },
        queryFilters: {
            type: [Object, String],
            required: false,
            default: null,
        },
        type: {
            type: String,
            default: 'alt',
        },
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        cancelDialog() {
            this.$emit('cancel')
        },
        async saveScreener(screener) {
            let screenerStore = useScreenerStore()
            try {
                this.loading = true
                if (screener.id) {
                    delete screener.id
                }

                const response = await PostScreener(screener)

                screenerStore.addScreener(response.data)
                this.$emit('submit')

                await this.$router.push({
                    name: response.data.type === 'COMPANY' ? 'Companies' : 'Markets',
                    params: { screenerId: response.data.id },
                    query: { showScreener: true },
                })
            } catch (err) {
                this.$logger.error(err)
                this.eventBus.emit('snacktime', {
                    type: 'error',
                    message: this.$t('screeners.screener.errors.create'),
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.create-modal {
    &.alt {
        :deep(.aon-col) {
            width: 100% !important;
            height: auto !important;
        }
    }
}
</style>
