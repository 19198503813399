<template>
    <div class="contender-selection-track">
        <SelectContenders
            :key="updater"
            :all-table-data="allContenders"
            :loading="false"
            :report-step="4"
        />
        <AonCoverLoading
            :loading="reportGenerationStore.shouldUpdateContenderList"
            title="Populating Potential Contenders"
        />
    </div>
</template>

<script setup>
import { ref, inject, onMounted, nextTick, computed, onActivated } from 'vue'
import { useReportGenerationStore, useMoat2ProductStore, useArenaOutputStore } from '@/stores'
import { getCompetitors } from '@/api/productAlignment.js'

import { default as SelectContenders } from '@/components/alignmentOutput/benchmarkPeers/OutputCompetitorSelection.vue'

const logger = inject('logger')

const reportGenerationStore = useReportGenerationStore()
const moat2ProductStore = useMoat2ProductStore()
const arenaOutputStore = useArenaOutputStore()
const emit = defineEmits(['update-tables'])

const updater = ref(0)

onMounted(async () => {
    await nextTick()
})

onActivated(async () => {
    await nextTick()

    if (reportGenerationStore.shouldUpdateContenderList) {
        reportGenerationStore.contenderSource = null
        try {
            await getCompetitors(
                moat2ProductStore.productAlignmentId
                    ? moat2ProductStore.productAlignmentId
                    : arenaOutputStore.outputJobPk,
                reportGenerationStore.reportSelections[2].value.map((techArea) => techArea.nodeId),
                null
            ).then((response) => {
                moat2ProductStore.allCompetitorData = response.data
                arenaOutputStore.allCompetitorData = response.data
                reportGenerationStore.reportSelections[3].value = response.data.slice(0, 1)
                reportGenerationStore.shouldUpdateContenderList = false
                updater.value++
            })
        } catch (err) {
            // catch if call fails
            logger.error(err)
        }
    }
})

const allContenders = computed(() => {
    return moat2ProductStore.allCompetitorData
})
</script>

<style lang="scss" scoped>
.contender-selection-track {
    height: 100%;
    width: 100%;

    position: relative;
}
</style>
