<template>
    <div class="vulnerabilities-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity || !moat2ProductStore.productAlignmentId"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <LitigationHistoryCard />
            <IndustryLitigationCard
                :key="updater"
                :loading="loadingData || moat2ProductStore.productAlignmentLoading"
                :data="litigationData"
            />
            <InnovationTrendsRiskCard
                :key="updater"
                :loading="loadingData || moat2ProductStore.productAlignmentLoading"
                :data="litigationData"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, inject, watch, onMounted } from 'vue'
import { useEntityStore, useMoat2ProductStore } from '@/stores'
import { getLitigationRisk } from '@/api/vulnerabilities.js'

import LitigationHistoryCard from '@/components/company/tabs/vulnerabilities/LitigationHistoryCard.vue'
import IndustryLitigationCard from '@/components/company/tabs/vulnerabilities/industryLitigation/IndustryLitigationCard.vue'
import InnovationTrendsRiskCard from '@/components/company/tabs/vulnerabilities/InnovationTrendsRiskCard.vue'

const entityStore = useEntityStore()
const moat2ProductStore = useMoat2ProductStore()

const logger = inject('logger')

const litigationData = ref([])
const loadingData = ref(true)
const updater = ref(0)

onMounted(async () => {
    if (moat2ProductStore.productAlignmentId) {
        fetchLitigationRisk()
    }
})

watch(
    () => moat2ProductStore.productAlignmentId,
    (newVal, oldVal) => {
        if (newVal) {
            loadingData.value = true
            fetchLitigationRisk()
        }
    }
)
watch(
    () => moat2ProductStore.productAlignmentLoading,
    (newVal, oldVal) => {
        if (!newVal) {
            loadingData.value = true
            fetchLitigationRisk()
        }
    }
)

const fetchLitigationRisk = async () => {
    if (moat2ProductStore.productAlignmentLoading) {
        return
    }
    try {
        const { data } = await getLitigationRisk(moat2ProductStore.productAlignmentId)
        if (data.length === 0) {
            litigationData.value = []
            return
        }

        litigationData.value = data
        updater.value++
        loadingData.value = false
    } catch (error) {
        logger.error(error)
    }
}
</script>

<style lang="scss" scoped>
.vulnerabilities-tab {
    width: 100%;
    position: relative;
}
</style>
