<template>
    <div class="arena-board-delete-confirmation" v-if="arenaStore.showDeleteConfirmation">
        <AonModal
            title="Are you sure you want to delete this arena?"
            message=""
            col-width="4"
            @close="arenaStore.showDeleteConfirmation = false"
        >
            <div class="modal-content">
                <h5 class="italic" style="font-style: italic">{{ props.name }}</h5>
                <div class="mt-8 button-holder d-flex align-items-center">
                    <AonButton
                        label="Confirm"
                        :loading="arenaDeleteLoading"
                        @clicked="onDeleteArena()"
                    />
                    <AonButton
                        class="ml-5"
                        type="ghost"
                        label="Cancel"
                        @clicked="arenaStore.showDeleteConfirmation = false"
                    />
                </div>
            </div>
        </AonModal>
    </div>
</template>

<script setup>
import { inject, ref } from 'vue'
import { deleteArena } from '@/api/arena.js'
import { useArenaStore } from '@/stores'

const emit = defineEmits(['update-arena'])
const eventBus = inject('eventBus')
const logger = inject('logger')
const arenaStore = useArenaStore()

const props = defineProps({
    productAlignmentPkOverwrite: {
        type: String,
        default: null,
    },
    name: {
        type: String,
        default: '',
    },
})

const arenaDeleteLoading = ref(false)

const onDeleteArena = async () => {
    arenaDeleteLoading.value = true

    try {
        const productAlignmentPk = props.productAlignmentPkOverwrite
            ? props.productAlignmentPkOverwrite
            : arenaStore.boardGlobalDetails.productAlignmentId

        await deleteArena(productAlignmentPk)
    } catch (err) {
        handleError(err)
    } finally {
        arenaDeleteLoading.value = false
        arenaStore.showDeleteConfirmation = false
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })
}
</script>

<style lang="scss" scoped>
.arena-board-view {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('/grid.png');

    .get-started {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        text-transform: uppercase;
    }

    .report-complete-cover {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    :deep(.aon-radio-group) {
        .radio-container {
            flex-wrap: wrap;

            .radio-button {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}
</style>
