<template>
    <div class="tpe-card">
        <AonCard
            title="Innovation Implied P/E"
            desc="Innovation Implied P/E is a predicted estimate for how a company would be valued or recognized, based on the valuation of comparable publicly traded entities participating in like arenas of innovation."
        >
            <template #header-append>
                <p v-if="hasFinancialOverview" class="grey02--text italic pl-2">
                    Last updated on {{ lastUpdated }}
                </p>
            </template>
            <div class="card-content d-flex flex-wrap">
                <AonSpinner
                    v-if="loadingFinancialOverview"
                    class="ma-auto"
                    :scale="0.5"
                    color="castleMoat"
                ></AonSpinner>

                <div
                    id="amChartTPE"
                    ref="amChartTPE"
                    v-if="loadingFinancialOverview || hasFinancialOverview"
                ></div>
                <div class="financial-overview-chart" v-if="hasFinancialOverview">
                    <p class="definition-blurb" v-html="tpeBlurb"></p>
                </div>
                <div v-else-if="!loadingFinancialOverview">
                    <p class="no-data italic">No financial data available</p>
                </div>
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref, computed, inject } from 'vue'
import { useEntityStore } from '@/stores'

import { useRoute } from 'vue-router'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { config } from '@/config'

const route = useRoute()
const entityId = route.params.targetPk
const filters = inject('filters')
const entityStore = useEntityStore()
const chartData = ref([])
const amChartTPE = ref(null)
const loadingFinancialOverview = ref(false)
const hasFinancialOverview = ref(false)
const tpeMedianColor = '#5372ac'
const tpeRangeColor = '#BCC9DF'
const peColor = '#143862'
let root
let yAxis
let xAxis

const lastUpdated = computed(() => {
    return filters.toUTCString(entityStore.financialOverview?.predictedEarningsUpdated ?? '1-1-1')
})
const tpeBlurb = computed(() => {
    const pe = entityStore.financialOverview?.actualEarnings ?? NaN
    const tpe = entityStore.financialOverview?.predictedEarnings ?? NaN
    const targetPlusRange = tpe + 5
    const targetMinusRange = tpe - 5 < 0 ? 0 : tpe - 5

    if (pe) {
        if (pe >= targetMinusRange && pe <= targetPlusRange) {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.toFixed(2)})</span> is near our <span style="font-weight: bold;color: ${tpeMedianColor};">Innovation Implied P/E (${tpe.toFixed(2)})</span>, indicating alignment with market expectations and balanced valuation.`
        } else if (pe < tpe) {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.toFixed(2)})</span> is trailing our <span style="font-weight: bold;color:${tpeMedianColor};">Innovation Implied P/E (${tpe.toFixed(2)})</span>, which may indicate untapped potential or inefficiencies in market execution.`
        } else {
            return `This company's current <span style="font-weight: bold;color: ${peColor};">P/E ratio (${pe.toFixed(2)})</span> is ahead our <span style="font-weight: bold;color: ${tpeMedianColor};">Innovation Implied P/E (${tpe.toFixed(2)})</span>, suggesting strong market performance or overvaluation.`
        }
    }
    return ``
})

onMounted(async () => {
    loadingFinancialOverview.value = true
    await entityStore.getFinancialOverview(entityId)
    hasFinancialOverview.value = entityStore.financialOverview
    if (hasFinancialOverview.value) {
        am5.addLicense(config.license.AMChartsLicense)
        root = am5.Root.new(amChartTPE.value)
        mapData()
        configureChart()
    }

    loadingFinancialOverview.value = false
})

onBeforeUnmount(() => {
    root.dispose()
})

const mapData = () => {
    chartData.value = [
        {
            company: entityStore.entity.name,
            pe: Math.ceil(entityStore.financialOverview.actualEarnings * 100) / 100,
            tpeLb: Math.ceil(entityStore.financialOverview.predictedEarningsLb * 100) / 100,
            tpe: Math.ceil(entityStore.financialOverview.predictedEarnings * 100) / 100,
            tpeUb: Math.ceil(entityStore.financialOverview.predictedEarningsUb * 100) / 100,
        },
    ]
}

const configureChart = (removeZeros = true) => {
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingLeft: 5,
            paddingRight: 15,
            paddingTop: 20,
            layout: root.verticalLayout,
            maxTooltipDistance: -1,
        })
    )

    generateAxes(chart)
    generateSeries(chart)

    let legend = chart.children.push(
        am5.Legend.new(root, {
            nameField: 'name',
            fillField: 'color',
            strokeField: 'color',
            centerX: am5.percent(50),
            x: am5.percent(50),
            paddingTop: 20,
            paddingBottom: 10,
        })
    )
    legend.data.setAll([
        { name: 'Innovation Implied P/E Confidence Range', color: am5.color(tpeRangeColor) },
        { name: 'Innovation Implied P/E Median', color: am5.color(tpeMedianColor) },
        { name: 'P/E', color: am5.color('#143862') },
    ])
}

const generateAxes = (chart) => {
    let yRenderer = am5xy.AxisRendererY.new(root, {})
    yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
            categoryField: 'company',
            renderer: yRenderer,
            visible: false,
            tooltip: am5.Tooltip.new(root, {}),
        })
    )

    yRenderer.grid.template.setAll({
        location: 1,
    })

    yAxis.data.setAll(chartData.value)

    let xRenderer = am5xy.AxisRendererX.new(root, {
        minGridDistance: 50,
        strokeOpacity: 0.1,
        minorGridEnabled: true,
    })
    xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
            min: 0,
            max: Math.max(
                entityStore.financialOverview.predictedEarningsUb,
                entityStore.financialOverview.actualEarnings,
                100
            ),
            maxPrecision: 0,
            renderer: xRenderer,
        })
    )
}

const generateSeries = (chart) => {
    let tpeRangeSeries = chart.series.push(
        am5xy.ColumnSeries.new(root, {
            name: 'Innovation Implied P/E',
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            clustered: false,
            openValueXField: 'tpeLb',
            valueXField: 'tpeUb',
            categoryYField: 'company',
            fill: am5.color(tpeRangeColor),
        })
    )

    // Set corner radius for the columns to create rounded edges
    tpeRangeSeries.columns.template.setAll({
        cornerRadiusTL: 10, // Top left corner
        cornerRadiusTR: 10, // Top right corner
        cornerRadiusBL: 10, // Bottom left corner
        cornerRadiusBR: 10, // Bottom right corner
    })

    tpeRangeSeries.columns.template.setAll({
        tooltipText: '[bold]{name}:[/] {categoryY}: {tpe}',
        tooltipY: am5.percent(100),
    })

    tpeRangeSeries.columns.template.setAll({
        height: am5.percent(50),
        strokeOpacity: 0,
    })

    let tpeMedianSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
            name: 'Innovation Implied P/E',
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            valueXField: 'tpe',
            categoryYField: 'company',
            stroke: am5.color(tpeMedianColor),
            stepWidth: am5.percent(75),
            noRisers: true,
        })
    )

    tpeMedianSeries.strokes.template.setAll({
        strokeWidth: 3,
    })

    tpeRangeSeries.data.setAll(chartData.value)
    tpeMedianSeries.data.setAll(chartData.value)

    tpeRangeSeries.appear()
    tpeMedianSeries.appear()

    if (entityStore.financialOverview.actualEarnings) {
        let peSeries = chart.series.push(
            am5xy.StepLineSeries.new(root, {
                name: 'P/E',
                xAxis: xAxis,
                yAxis: yAxis,
                baseAxis: yAxis,
                valueXField: 'pe',
                categoryYField: 'company',
                clustered: false,
                stroke: am5.color('#143862'),
                stepWidth: am5.percent(75),
                noRisers: true,
                tooltipText: '[bold]P/E:[/] {categoryY}: {pe}',
                tooltipPosition: 'pointer',
            })
        )

        peSeries.strokes.template.setAll({
            strokeWidth: 3,
        })

        peSeries.data.setAll(chartData.value)
        peSeries.appear()
    }
}
</script>

<style lang="scss" scoped>
.tpe-card {
    width: 100%;
    position: relative;

    #amChartTPE {
        width: 100%;
        height: 200px;
        div {
            height: 100%;
        }
    }
}
</style>
