import axios from 'axios'

let baseUrl = `api/productalignment`

export function requestArena(
    rootNodeId,
    includedNodes,
    newArenaName = 'My Arena Builder',
    config = null
) {
    const requestBody = {
        productAlignmentType: 'arena',
        name: newArenaName,
        seedRootNodeId: rootNodeId,
        includeSeedNodes: includedNodes,
        config,
    }

    return axios.post(`${baseUrl}`, requestBody)
}

export function addNode(requestBody) {
    return axios.post(`api/ipnode`, requestBody)
}

export function updateNodes(requestBody) {
    return axios.patch(`api/ipnode`, requestBody)
}

export async function pollForChildNodes(nodeId, success, failure) {
    let statusResp
    try {
        statusResp = await axios.post(`api/ipnode/${nodeId}/children`)
    } catch (err) {
        failure(err)
    }
    if (!statusResp || statusResp.error) {
        return failure()
    }

    switch (statusResp.data.childGenStatus) {
        case 'requested':
            return

        case 'set':
            return success(statusResp.data)

        case 'notSet':
            return failure(statusResp.data)

        default:
            return failure(statusResp.data)
    }
    return
}

// Product alignment enpoints...edit these if we ever separate endpoints into arenas/PA
export function deleteArena(productAlignmentId) {
    return axios.delete(`${baseUrl}/${productAlignmentId}`)
}

export function patchArena(productAlignmentId, params) {
    return axios.patch(`${baseUrl}/${productAlignmentId}`, params)
}

export function getArenaRuns(params) {
    return axios.get(baseUrl, { params, paramsSerializer: { indexes: null } })
}

export function getArenaNodes(productAlignmentId) {
    return axios.get(`${baseUrl}/${productAlignmentId}`)
}

export function startArenaOutputGeneration(productAlignmentId) {
    return axios.post(`${baseUrl}/${productAlignmentId}/start`)
}

export async function pollForJobData(prodAlignmentId, success, failure) {
    let statusResp
    try {
        statusResp = await axios.get(`${baseUrl}/${prodAlignmentId}`)
    } catch (err) {
        failure(err)
    }

    if (!statusResp || statusResp.error) {
        return failure()
    }

    switch (statusResp.data.status) {
        case 'processing':
            return
        case 'nodesComplete':
            return
        case 'countsComplete':
            return
        case 'clusteringComplete':
            return

        case 'reportComplete':
            return success(statusResp.data)

        case 'error':
            return failure(statusResp.data)

        default:
            return failure(statusResp.data)
    }
    return
}
