<template>
    <div class="arena-board-view">
        <div
            class="arena-header knights-cloak-80 pa-5 w-full d-flex justify-content-between align-items-center"
        >
            <div class="copy-holder d-flex flex-wrap">
                <div class="edit d-flex align-items-center">
                    <h4 class="white--text w-full overflow-ellipsis mr-2">
                        {{ arenaStore.boardGlobalDetails?.name }}
                    </h4>
                    <font-awesome-icon
                        icon="fas fa-pencil"
                        class="white--text icon mr-3 edit-icon"
                        size="xl"
                        @click="arenaStore.showEditModal = true"
                    />
                </div>
                <p class="white--text w-full">
                    Evaluate a target entity, relative to its peers in terms of anticipated IP
                    posture.
                </p>
            </div>
            <div
                id="context-target-generate"
                class="action-holder d-flex justify-content-end"
                style="position: relative; z-index: 1"
            >
                <AonButton
                    :class="{ disabled: disableGenerate || generateLoading }"
                    type="prominent"
                    label="Generate Innovation Space"
                    class="ml-4"
                    @clicked="generateArenaOutput()"
                />
            </div>
        </div>
        <ArenaBoard
            v-if="showArenas && boardData.length > 1"
            :starting-board-data="boardData"
            :key="arenaBoardUpdater"
        />
        <h3
            v-if="(showArenas && !arenaId) || boardData.length <= 1 || computedBoardData"
            class="get-started grey04--text w-full text-center"
        >
            Add a Company or Concept Element to get started
        </h3>
        <div
            id="arena-control-buttons"
            class="d-flex"
            :class="{ shiftedLeft: arenaStore.selectedNode }"
        >
            <ArenaBoardAddNode @node-added="getArenaTree(null, true)" />
            <ArenaBoardControlButton
                v-if="arenaConfigEnabled"
                icon="fas fa-gear"
                :disabled="aggregateLoading || arenaStore.arenaBoardLoading"
                @clicked="showPromptConfig = true"
            />
        </div>
        <AonCoverLoading
            style="z-index: 3"
            :loading="aggregateLoading || arenaStore.arenaBoardLoading"
            title="Aggregating Element Structure"
        ></AonCoverLoading>
        <AonCoverLoading :loading="!showArenas" :show-spinner="false" title="Coming Soon" />
        <ArenaBoardEditModal :name="arenaStore.boardGlobalDetails?.name" />
        <ArenaBoardConfigModal
            v-if="showPromptConfig"
            :config="arenaStore.boardGlobalDetails?.config"
            @close="showPromptConfig = false"
        />

        <div v-if="arenaStore.activeContextId" class="context-dimmer"></div>
    </div>
</template>

<script setup>
import {
    onBeforeMount,
    inject,
    ref,
    onMounted,
    onBeforeUnmount,
    computed,
    watch,
    onUpdated,
    nextTick,
} from 'vue'
import { requestArena, getArenaNodes, startArenaOutputGeneration } from '@/api/arena.js'
import { useAppStore, useArenaStore } from '@/stores'
import { useRoute, useRouter } from 'vue-router'

import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'

import ArenaBoard from '@/components/arenaBoard/ArenaBoard'
import ArenaBoardAddNode from '../components/arenaBoard/ArenaBoardAddNode.vue'
import ArenaBoardEditModal from '../components/arenaBoard/ArenaBoardEditModal.vue'
import ArenaBoardConfigModal from '@/components/arenaBoard/ArenaBoardConfigModal.vue'
import ArenaBoardControlButton from '@/components/arenaBoard/ArenaBoardControlButton.vue'
const showArenas = useFlag('ipAlpha.showArenas')

const unleashPromptConfig = useFlag('ipAlpha.promptConfig')
const arenaPatentKeywordsFlag = useFlag('ipAlpha.arenaPatentKeywords')

const eventBus = inject('eventBus')
const { t } = useI18n()
const logger = inject('logger')
const route = useRoute()
const router = useRouter()

const arenaStore = useArenaStore()
const appStore = useAppStore()

const boardData = ref([])
const error = ref(null)
const arenaId = ref(null)
const arenaBoardUpdater = ref(0)
const generateLoading = ref(false)
const showPromptConfig = ref(false)

onBeforeMount(() => {
    appStore.pushFooter = true
})

onMounted(() => {
    checkForArenaId()
    eventBus.on('node-added', (data) => {
        getArenaTree(data.nodeId)
    })
})

onBeforeUnmount(() => {
    arenaStore.resetState()
    eventBus.all.clear()
})

watch(
    () => route.params.arenaSourceId,
    (newVal, oldData) => {
        if (newVal !== oldData) {
            checkForArenaId()
        }
    }
)

const computedBoardData = computed(() => {
    return arenaStore.boardData.length === 0
})

const disableGenerate = computed(() => {
    return !arenaStore.allowGenerate || arenaStore.boardData.length === 0
})

const aggregateLoading = computed(() => {
    if (boardData.value.length <= 1) {
        return false
    }

    if (arenaStore.arenaBoardLoading && showArenas.value && arenaId.value) {
        return true
    } else {
        return false
    }
})

const arenaConfigEnabled = computed(() => {
    return arenaPatentKeywordsFlag.value || unleashPromptConfig.value
})

const checkForArenaId = async (focusId = null) => {
    arenaId.value = route.params.arenaSourceId
    arenaStore.arenaBoardLoading = true

    if (!arenaId.value && showArenas.value) {
        // if there is no id, start a new arena
        try {
            const { data } = await requestArena(null, null)
            arenaStore.rootNodeId = data.rootNodeId
            router.push({ name: 'Arena', params: { arenaSourceId: data.productAlignmentId } })
        } catch (err) {
            handleError('And error occurred')
            error.value = true
        } finally {
            if (focusId) {
                arenaStore.focusedNode = focusId
            }
            arenaStore.arenaBoardLoading = false
        }
    } else {
        getArenaTree(focusId, true)
    }
}

const getArenaTree = async (focusId, clear = false) => {
    arenaStore.arenaBoardLoading = true
    if (clear) {
        boardData.value = []
    }

    let data
    try {
        data = await getArenaNodes(arenaId.value)
        arenaStore.boardGlobalDetails = data.data
        boardData.value = data.data.nodes
        arenaStore.rootNodeId = data.data.rootNodeId

        if (data.data.status === 'reportComplete') {
            copyArenaToNew()
        }
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    } finally {
        if (data?.data?.status !== 'reportComplete') {
            if (focusId) {
                arenaStore.focusedNode = focusId
            }
            arenaStore.arenaBoardLoading = true
        }
        arenaStore.arenaBoardLoading = false
    }
}

const generateArenaOutput = async () => {
    generateLoading.value = true
    try {
        const { data } = await startArenaOutputGeneration(route.params.arenaSourceId)
        router.push({ name: 'Tailored Output', params: { jobPk: route.params.arenaSourceId } })
    } catch (err) {
        handleError('And error occurred')
        error.value = true
    } finally {
        generateLoading.value = false
    }
}

const copyArenaToNew = async () => {
    arenaStore.arenaBoardLoading = true
    try {
        const { data } = await requestArena(
            arenaStore.rootNodeId,
            boardData.value.filter((item) => item.include).map((item) => item.nodeId),
            setName(),
            arenaStore.boardGlobalDetails?.config
        )
        await nextTick()
        setTimeout(() => {
            // Next tick not working? Remove timeouts when time permits - BB
            router.push({ name: 'Arena', params: { arenaSourceId: data.productAlignmentId } })
            setTimeout(() => {
                arenaBoardUpdater.value++
            }, 1000)
        }, 1000)
    } catch (err) {
        logger.error(err)
    }
    arenaStore.arenaBoardLoading = true
}

const setName = () => {
    const name = arenaStore.boardGlobalDetails.name
    const versionMatch = name.match(/V(\d+)/)
    if (versionMatch) {
        const currentVersion = parseInt(versionMatch[1])
        const newName = name.replace(/V\d+$/, '')
        return `${newName} V${currentVersion + 1}`
    } else {
        return `${name} V1`
    }
}

const handleError = (err) => {
    logger.error(err)

    eventBus.emit('snacktime', {
        type: 'error',
        message: err,
    })

    error.value = true
}
</script>

<style lang="scss" scoped>
.arena-board-view {
    width: 100%;
    height: calc(100vh - 64px); // minus app header and app footer
    position: relative;
    background: url('/grid.png');
    overflow: hidden;

    .get-started {
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);

        text-transform: uppercase;
    }

    .edit-icon {
        cursor: pointer;
    }

    .report-complete-cover {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.6);

        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    .context-dimmer {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }

    :deep(.aon-radio-group) {
        .radio-container {
            flex-wrap: wrap;

            .radio-button {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }

    #arena-control-buttons {
        position: absolute;
        top: calc(90px + 25px);
        right: 30px;
        z-index: 3;

        flex-direction: row;

        transition: all 0.3s ease-in-out;

        :deep(> div),
        > div {
            margin-right: 10px;
        }

        &.shiftedLeft {
            transform: translateX(-350px);
        }
    }
}
</style>
