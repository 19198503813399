import Moment from 'moment'
import NumberAbbreviate from 'number-abbreviate'
import { pluralize as toPlural } from 'pluralize'

const numberAbbreviate = new NumberAbbreviate(['K', 'M', 'B', 'T'])
const noValueText = '--'

export const abbreviate = (val, decimals = 0, noValueTextOverride = noValueText) => {
    if (!val) {
        return noValueTextOverride
    }
    if (isNaN(val)) {
        // If val exists and isNaN then probably already abbreviated
        return val
    }

    return `$${numberAbbreviate.abbreviate(val, decimals)}`
}
export const country = (val, noValueTextOverride = noValueText) => {
    if (val) {
        return val
    }

    return noValueTextOverride
}
export const formatPrecision = (val) => {
    if (!val || ['--', 'N/A'].includes(val)) {
        return val
    }
    if (val === parseInt(val)) {
        return val
    }

    const parsedMatch = val.toFixed(6).match(/^-?\d*\.?0*\d{0,2}/)[0]

    if (parsedMatch[parsedMatch.length - 1] === '0') {
        return parsedMatch.slice(0, parsedMatch.length - 1)
    }

    return parsedMatch
}
export const longDate = (val, noValueTextOverride = noValueText) => {
    if (val) {
        return Moment(String(val)).format('D-MMM-YYYY')
    }

    return noValueTextOverride
}
export const na = (val, noValueTextOverride = 'N/A') => {
    if (val) {
        return val
    }

    return noValueTextOverride
}
export const pluralize = (val, number) => {
    return toPlural(val, Math.floor(number))
}
export const portfolioConcentration = (val, noValueTextOverride = noValueText) => {
    if (val) {
        return `${val}%`
    }

    return noValueTextOverride
}
export const toLocaleString = (val, locales = 'en-US', noValueTextOverride = noValueText) => {
    if (!val) {
        return noValueTextOverride
    }

    return val.toLocaleString(locales)
}
export const toPercentage = (decimalVal) => {
    return Math.trunc(decimalVal * 100)
}
export const toUTCString = (val) => {
    // TODO shouldn't this return an empty string with the name toUTCString?
    if (!val) {
        return null
    }
    // TODO Hack to create Date object with UTC date string
    const d = new Date(`${val}`)

    return `${d.getUTCMonth() + 1}-${d.getUTCDate()}-${d.getUTCFullYear()}`
}
export const toUTCStringPlusTime = (val) => {
    if (!val) {
        return
    }
    var valToISO = new Date(`${val}`)
    var finalDate = new Date(`${val}`)
    finalDate.setMinutes(valToISO.getMinutes())

    return (
        `${
            finalDate.getUTCMonth() + 1
        }-${finalDate.getUTCDate()}-${finalDate.getUTCFullYear()} at ` +
        finalDate.toLocaleString([], { timeStyle: 'short' })
    )

    // const d = new Date(`${val}`)

    // return `${d.getHours()}:${d.getMinutes()}`
}
export const trunc = (val, noValueTextOverride = noValueText) => {
    // USE this when NEGATIVE or positive numbers are expected
    if (!val) {
        return noValueTextOverride
    }

    return Math.trunc(val)
}
export const zScore = (val, noValueTextOverride = noValueText) => {
    if (['--', 'N/A'].includes(val)) {
        return val
    }
    if (!val || isNaN(val)) {
        return noValueTextOverride
    }
    if (val > -1 && val < 1) {
        return 0
    }

    return Math.trunc(val)
}

export default {
    abbreviate,
    country,
    formatPrecision,
    longDate,
    na,
    pluralize,
    portfolioConcentration,
    toLocaleString,
    toPercentage,
    toUTCString,
    toUTCStringPlusTime,
    trunc,
    zScore,
}
