<template>
    <div class="arena-board">
        <div class="board-holder">
            <div id="arena-container" ref="graphRef" />
        </div>
        <ArenaBoardSlideOut v-if="arenaStore.selectedNode" />
    </div>
</template>

<script setup>
import { ref, watch, onMounted, inject, nextTick } from 'vue'
import { Graph, Extensions, extend } from '@antv/g6'
import { useArenaStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { defaultNodeStyles, defaultEdgeStyles } from '@/composables/ArenaBoardDefaults.js'
import { debounce } from 'lodash-es'

import ArenaBoardSlideOut from './ArenaBoardSlideOut.vue'

const arenaStore = useArenaStore()
const { t } = useI18n()
const route = useRoute()
const eventBus = inject('eventBus')

const props = defineProps({
    startingBoardData: {
        type: Array,
        default: () => [],
    },
})

const graphRef = ref(null)
const graph = ref(null)
const minimapStyles = ref(null)
const ExtGraph = extend(Graph, {
    layouts: {
        dagre: Extensions.DagreLayout,
    },
    nodes: {
        'modelRect-node': Extensions.ModelRectNode,
    },
    edges: {
        'cubic-horizontal-edge': Extensions.CubicHorizontalEdge,
    },
    plugins: {
        minimap: Extensions.Minimap,
    },
})
let graphData = null

onMounted(() => {
    if (props.startingBoardData) {
        aggregateData(true)

        window.addEventListener(
            'resize',
            debounce(
                async () => {
                    handleResize(true)
                },
                500,
                true
            )
        )
    }
})

watch(
    () => props.startingBoardData,
    (newVal, oldData) => {
        if (newVal !== oldData) {
            setTimeout(() => {
                aggregateData(false)
            }, 200)
        }
    }
)

watch(
    () => arenaStore.focusedNode,
    (newVal, oldData) => {
        if (newVal) {
            focusNode(newVal)
        }
    }
)

const aggregateData = (init = false) => {
    arenaStore.boardData = props.startingBoardData.filter((item) => item.include !== false)

    if (arenaStore.boardData.length === 0) {
        if (graph.value) {
            graph.value?.clear()
        }
        return
    }

    let nodes = arenaStore.boardData.map((item) => {
        return {
            id: item.nodeId,
            parent: item.parent,
            data: {
                nodeType: item.type,
                name: item.displayName,
                included: item.include,
                width: item.displayName.length,
                entityPk: item.internalIdentifier,
            },
        }
    })

    let edges = arenaStore.boardData
        .map((item) => {
            if (item.parent && item.parent !== item.rootNodeId) {
                return {
                    id: `${item.parent}-${item.nodeId}`,
                    source: item.parent,
                    target: item.nodeId,
                }
            }
        })
        .filter((item) => item)

    const companiesWithoutProducts = arenaStore.boardData
        .filter((item) => item.type === 'company')
        .some((company) => {
            const hasProductEdge = edges.some(
                (edge) => edge.source === company.nodeId || edge.target === company.nodeId
            )
            return !hasProductEdge
        })

    const hasEdges = edges.length >= 1
    const hasSingleConcept =
        arenaStore.boardData.length === 1 && arenaStore.boardData[0].type === 'concept'
    const hasCompany = arenaStore.boardData.find((item) => item.type === 'company')
    const hasConcept = arenaStore.boardData.find((item) => item.type === 'concept')
    const anyData = arenaStore.boardData.length > 0

    switch (true) {
        case !anyData:
            arenaStore.allowGenerate = false
            break
        case companiesWithoutProducts:
            arenaStore.allowGenerate = false
            eventBus.emit('snacktime', {
                type: 'warning',
                message: 'All companies must be connected to at least one product.',
            })
            break
        case hasSingleConcept:
            arenaStore.allowGenerate = true
            break
        case hasCompany && hasEdges:
            arenaStore.allowGenerate = true
            break
        case hasConcept && hasEdges:
            arenaStore.allowGenerate = true
            break

        default:
            arenaStore.allowGenerate = false
            break
    }

    graphData = {
        nodes: nodes,
        edges: edges,
        combos: [],
    }
    setupArena(init)
    setMiniMapEvents()
}

const setupArena = async (init = false) => {
    graph.value?.clear()
    graph.value = new ExtGraph({
        container: graphRef.value,
        renderer: 'canvas',
        zoom: 8,
        layout: {
            type: 'dagre',
            ranksep: 200,
            nodesep: 30,
            rankdir: 'LR',
            align: 'DL',
        },
        node: (model) => {
            const iconType = model.data.nodeType === 'product' ? 'concept' : model.data.nodeType
            const iconIncluded = model.data.included ? 'included' : 'not_included'
            const iconBuilder = `/${iconType}_${iconIncluded}.png`

            return defaultNodeStyles(model, iconBuilder)
        },
        nodeState: {
            selected: {
                keyShape: {
                    lineWidth: 3,
                    stroke: '#2A79D2',
                },
                labelShape: {
                    dy: -5,
                },
            },
        },
        edge: defaultEdgeStyles(),
        // autoFit: 'view',
        autoFit: 'center',
        modes: {
            default: [
                {
                    type: 'drag-canvas',
                },
                {
                    type: 'zoom-canvas',
                    maxZoom: 1,
                    minZoom: 0.4,
                },

                {
                    type: 'click-select',
                    itemTypes: ['node'],
                },
            ],
        },
        plugins: [
            {
                type: 'lod-controller',
                disableLod: true,
            },
        ],
        data: graphData,
    })

    graph.value.addPlugins([
        {
            key: 'minimap1',
            type: 'minimap',
            mode: 'delegate',
            className: 'minimap',
            size: [100, setMinimapHeight()],
        },
    ])

    graph.value.on('node:click', async (event) => {
        const { itemId } = event
        let nodeData = graph.value.getNodeData(itemId)

        if (arenaStore.selectedNode) {
            if (nodeData.id === arenaStore.selectedNode.id) {
                graph.value.setItemState(nodeData.id, 'selected', true)
            } else {
                graph.value.clearItemState(arenaStore.selectedNode.id)
                arenaStore.selectedNode = null
            }
        }

        await nextTick()
        arenaStore.selectedNode = nodeData
        await nextTick()

        let targetParent = arenaStore.boardData.find((node) => {
            return node.id === arenaStore.selectedNode.parent
        })

        if (targetParent) {
            arenaStore.selectedNodeParent = targetParent
        } else {
            arenaStore.selectedNodeParent = {
                id: arenaStore.boardData[0].rootNodeId,
                displayName: 'Arena Root',
                type: 'concept',
            }
        }
    })

    graph.value.on('canvas:click', (event) => {
        graph.value.clearItemState(arenaStore.selectedNode?.id)
        arenaStore.selectedNode = null
    })

    setTimeout(() => {
        if (arenaStore.selectedNode) {
            graph.value.clearItemState(arenaStore.selectedNode.id)
            graph.value.setItemState(arenaStore.selectedNode.id, 'selected', true)
        }
    }, 1000)

    if (init) {
        focusNode(graphData.nodes[0].id)
    } else if (!arenaStore.selectedNode) {
        focusNode(graphData.nodes[0].id)
    } else if (arenaStore.selectedNode) {
        focusNode(arenaStore.selectedNode.id)
    }
}

const focusNode = (passedId = null) => {
    setTimeout(() => {
        handleResize()
        setTimeout(() => {
            graph.value.focusItem(passedId, {
                easing: 'ease-in-out',
                duration: 1000,
            })
            setTimeout(() => {
                arenaStore.arenaBoardLoading = false
            }, 1000)
        }, 200)
    }, 200)
}

const handleResize = (center = false) => {
    let target = document.getElementById('arena-container')?.getBoundingClientRect()
    if (target) {
        graph.value?.setSize([target.width, target.height])
    }
    if (center) {
        graph.value?.fitCenter()
    }

    if (target.height < 950) {
        arenaStore.forceConceptInputClose = true
    } else {
        arenaStore.forceConceptInputClose = false
    }
}

const setMinimapHeight = () => {
    let finalHeight = arenaStore.boardData.length * 9
    finalHeight = finalHeight < 100 ? 100 : finalHeight
    return finalHeight > 400 ? 400 : finalHeight
}

const setMiniMapEvents = () => {
    let targetMiniMap = document.getElementsByClassName('minimap')[0]
    targetMiniMap.addEventListener('dblclick', (e) => {
        graph.value.fitCenter()
    })
}
</script>

<style lang="scss" scoped>
.arena-board {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    .board-holder {
        width: 100%;
        height: 100%;

        position: relative;
    }

    .arena-steps {
        position: absolute;
        top: 0;
        left: 0;

        width: 350px;
    }

    #arena-container {
        width: 100%;
        height: 100%;
    }

    :deep(.minimap) {
        position: absolute;
        top: 10px;
        left: 10px;
        background: white;
        box-shadow:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12),
            0 1px 5px 0 rgba(0, 0, 0, 0.2);
    }
}
</style>
