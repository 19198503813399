<template>
    <div class="valuations-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <AonContainer add-page-padding>
                <AonRow v-if="entityStore.entity.stock_ticker" class="mt-5 mx-0">
                    <AonCol class="aon-col-12 pa-0">
                        <SymbolBanner />
                    </AonCol>
                </AonRow>
                <AonRow class="mt-5">
                    <AonCol class="aon-col-12 pa-0">
                        <TPECard />
                    </AonCol>
                </AonRow>
                <AonRow class="mt-5 mx-0">
                    <AonCol class="aon-col-12 pa-0">
                        <IPValueCard />
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
    </div>
</template>

<script setup>
import { watch, onMounted, computed } from 'vue'
import { useEntityStore } from '@/stores'

import { useRoute } from 'vue-router'
import SymbolBanner from '@/components/widgets/financial/SymbolBanner.vue'
import TPECard from './TPECard.vue'
import IPValueCard from './IPValueCard.vue'

const entityStore = useEntityStore()
const route = useRoute()
const entityId = route.params.targetPk

onMounted(async () => {})
</script>

<style lang="scss" scoped>
.valuations-tab {
    width: 100%;
    position: relative;
}
</style>
