<template>
    <div class="innovation-table h-full">
        <AonContainer add-page-padding class="h-full">
            <AonRow class="mt-5 h-full">
                <AonCol class="aon-col-12 pa-0">
                    <AonCard title="Innovation Landscape" class="h-full">
                        <AonInput
                            class="mb-5"
                            style="width: 500px"
                            v-model="searchTerm"
                            search-input
                            label="Search Technology Areas"
                            placeholder="Search Technology Areas"
                            @keyup="searchTable()"
                            @clear="clearSearch()"
                        />
                        <MoatTable
                            :style="`width: 100%; height: 74vh;`"
                            :key="`tech_areas_${tableUpdater}`"
                            :class="themeClass"
                            :column-defs="colDefs"
                            row-selection="multiple"
                            :row-multi-select-with-click="true"
                            :suppress-auto-size="true"
                            :tooltip-interaction="true"
                            :tooltip-mouse-track="true"
                            :tooltip-show-delay="500"
                            tooltip-show-mode="whenTruncated"
                            :autoGroupColumnDef="autoGroupColumnDef"
                            :groupRemoveLowestSingleChildren="true"
                            :groupSelectsChildren="true"
                            :groupDisplayType="groupDisplayType"
                            :treeData="true"
                            :getDataPath="getDataPath"
                            :row-data="props.tableData"
                            :sortingOrder="['desc', 'asc', null]"
                            @grid-ready="gridInit"
                            @first-data-rendered="onFirstDataRendered"
                            @cell-clicked="onCellClicked"
                        />
                    </AonCard>
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { ref, inject, onBeforeMount, nextTick } from 'vue'
import { useEntityStore } from '@/stores'
import { useRouter } from 'vue-router'

// Moat Table
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'
import { useI18n } from 'vue-i18n'
import { requestArena } from '@/api/arena.js'

const logger = inject('logger')
const eventBus = inject('eventBus')
const { t } = useI18n()
const router = useRouter()
const entityStore = useEntityStore()

// Refs/Props
const props = defineProps({
    tableData: {
        type: Array,
        default: () => [],
    },
    loading: {
        type: Boolean,
        default: true,
    },
})

const searchTerm = ref(null)
const tableUpdater = ref(0)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        hide: false,
        field: 'type',
        flex: 1,
        lockPosition: 'left',
        maxWidth: 200,
        valueFormatter: (p) => {
            if (p.data) {
                return t(`productAlignment.nodeTypes.${p.data.type}`)
            }
        },
    },
    {
        rowGroup: true,
        hide: true,
        field: 'parentDisplayName',
        tooltipField: 'parentDisplayName',
        sortingOrder: ['asc', 'desc', null],
    },
    {
        headerName: 'Customize in Arenas',
        field: 'action',
        cellRenderer: 'MTCustomCellConfigureInArenas',
        width: 200,
        suppressMovable: true,
    },
])
const autoGroupColumnDef = ref({})
const groupDisplayType = ref('singleColumn')
const gridApi = ref(null)
const nodeMap = ref(null)
const arenaSetupLoading = ref(false)

onBeforeMount(() => {
    defineTableGroups()
    eventBus.on('setup-arena', setupArena)
})

const getDataPath = (data) => {
    let nodeMap = getNodeMap()
    let hierarchy = [data.displayName]
    while (data.parent) {
        const parentNode = nodeMap.get(data.parent)
        if (parentNode) {
            hierarchy.unshift(parentNode.displayName)
            data = parentNode
        } else {
            break
        }
    }
    return hierarchy
}

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const defineTableGroups = () => {
    autoGroupColumnDef.value = {
        minWidth: 450,
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: 'Technologies',
            headerName: 'Technologies',
        },
        field: 'displayName',
        cellRendererParams: {
            innerRenderer: 'MTCustomCell',
        },
        tooltipValueGetter: (params) => {
            if (!params.data) {
                return
            }
            return params.data.tooltipText
        },
        flex: 1,
        suppressMovable: true,
        //lockPosition: 'left',
        sort: 'asc',
        sortingOrder: ['asc', 'desc', null],
    }
}

const onFirstDataRendered = async (params) => {
    params.api.forEachNode((row) => {
        if (row.level === 0) {
            params.api.setRowNodeExpanded(row, true)
        }
    })
}

const getNodeMap = () => {
    if (nodeMap.value) {
        return nodeMap.value
    }
    let map = new Map()
    props.tableData.forEach((node) => {
        map.set(node.id, node)
    })
    nodeMap.value = map
    return map
}

const onCellClicked = async (cell) => {
    // allow for all types eventually
    // const validTypes = ['concept', 'product', 'product_category', 'problem_statement', 'cluster']
    const validTypes = ['concept', 'problem_statement']
    if (cell.column.colId !== 'type' && cell.column.colId !== 'action') {
        if (validTypes.includes(cell.data.type)) {
            entityStore.drillInTargetId = cell.data.id
            await nextTick()
            entityStore.showDrillIn = true
        }
    }
}

// search filter
const searchTable = () => {
    gridApi.value.setGridOption('quickFilterText', searchTerm.value)
}
const clearSearch = () => {
    searchTerm.value = ''
    searchTable()
}

const setupArena = async (rowData) => {
    arenaSetupLoading.value = true
    const getParentNodes = (node) => {
        let parentNodes = []
        while (node.parent) {
            parentNodes.push(node.parent)
            node = getNodeMap().get(node.parent)
        }
        return parentNodes
    }
    try {
        // Get all nodes in hierarchy
        const allChildNodes = [
            rowData.node.data.id,
            ...rowData.node.allLeafChildren.map((node) => node.data.id),
            ...getParentNodes(rowData.node.data),
        ]
        const { data } = await requestArena(
            props.tableData[0].rootNodeId,
            allChildNodes,
            props.tableData.find((node) => node.rootNodeId === node.nodeId).displayName
        )

        router.push({ name: 'Arena', params: { arenaSourceId: data.productAlignmentId } })
    } catch (err) {
        logger.error(err)
    } finally {
        arenaSetupLoading.value = false
    }
}
</script>

<style lang="scss" scoped>
.innovation-table {
    width: 100%;
    position: relative;

    .table-holder {
        position: relative;
    }
}
</style>
