import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
    faLock,
    faStar,
    faAngleLeft,
    faAngleDown,
    faArrowLeftFromLine,
    faArrowLeftToBracket,
    faArrowUpArrowDown,
    faBarsFilter,
    faBackwardStep,
    faBuilding,
    faBuildings,
    faCalendar,
    faCaretRight,
    faCaretDown,
    faChartSimple,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faCircleArrowRight,
    faCircleInfo,
    faCirclePlus,
    faCog,
    faEarthAmericas,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faEye,
    faExclamation,
    faExternalLink,
    faFlag,
    faGlobe,
    faHeadset,
    faHome,
    faMagnifyingGlass,
    faMapMarkerAlt,
    faMicrochip,
    faPaperPlane,
    faPhone,
    faPlus,
    faRankingStar,
    faSackDollar as fasSackDollar,
    faSitemap,
    faSquareArrowUpRight,
    faTableTree,
    faTrashCan,
    faXmark,
    faUser,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faArrowDownRight,
    faArrowRight,
    faArrowRightLong,
    faArrowLeftLong,
    faThList,
    faChartLine,
    faChartLineUp,
    faCheck,
    faListDropdown,
    faCodeCompare,
    faBooks,
    faDownload,
    faChevronDown,
    faLocationCrosshairs,
    faFileContract,
    faFileArrowDown,
    faPrint,
    faChevronsLeft,
    faTableLayout,
    faTimeline,
    faChartNetwork,
    faMerge,
    faViolin,
    faPuzzlePieceSimple,
    faPuzzle,
    faRotate,
    faChartScatter,
    faArrowsFromLine,
    faObjectsColumn,
    faSwords,
    faCircleExclamation,
    faThumbTack,
    faTruckContainer,
    faDumbbell,
    faChartTreeMap,
    faCity,
    faMemo,
    faBoxesStacked,
    faPencil,
    faBullseye,
    faGrid2,
    faSparkles,
    faGear,
    faCircleMinus,
    faBook,
} from '@fortawesome/pro-solid-svg-icons'
import { faStore as fadStore } from '@fortawesome/pro-duotone-svg-icons'
import {
    faDiagramSubtask,
    faFileLines,
    faSackDollar,
    faSitemap as farSitemap,
    faCircleQuestion,
} from '@fortawesome/pro-regular-svg-icons'
import { faCircleExclamation as falCircleExclamation } from '@fortawesome/pro-light-svg-icons'

library.add(
    faStar,
    faCircleMinus,
    faGear,
    faSparkles,
    faTimeline,
    faGrid2,
    faBullseye,
    faPencil,
    faBoxesStacked,
    faMemo,
    faCity,
    faChartTreeMap,
    faDumbbell,
    faThumbTack,
    faSwords,
    faObjectsColumn,
    faArrowsFromLine,
    faRotate,
    faPuzzlePieceSimple,
    faPuzzle,
    faViolin,
    faMerge,
    faChartNetwork,
    faTableLayout,
    faChevronsLeft,
    faPrint,
    faAngleLeft,
    faAngleDown,
    faArrowDownRight,
    faArrowLeftToBracket,
    faArrowUpArrowDown,
    faBarsFilter,
    faArrowLeftFromLine,
    faArrowRight,
    faArrowRightLong,
    faArrowLeftLong,
    faArrowUpRight,
    faArrowUpRightFromSquare,
    faBackwardStep,
    faBooks,
    faBuilding,
    faBuildings,
    faCalendar,
    faChartSimple,
    faCaretRight,
    faCaretDown,
    faChartLine,
    faChartLineUp,
    faCheck,
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faCircle,
    faCircleArrowRight,
    faCircleInfo,
    faCirclePlus,
    faCircleQuestion,
    faCodeCompare,
    faCog,
    faDiagramSubtask,
    faDownload,
    fadStore,
    faEarthAmericas,
    faEdit,
    faEllipsisH,
    faEnvelope,
    faEye,
    faExclamation,
    faExternalLink,
    faFileLines,
    faFileArrowDown,
    faFlag,
    faLock,
    faGlobe,
    faHeadset,
    faHome,
    faListDropdown,
    faLocationCrosshairs,
    faMagnifyingGlass,
    faMapMarkerAlt,
    faMicrochip,
    faPaperPlane,
    faPhone,
    faPlus,
    faRankingStar,
    farSitemap,
    faSackDollar,
    faSitemap,
    faSquareArrowUpRight,
    fasSackDollar,
    faTableTree,
    faThList,
    faTrashCan,
    faTruckContainer,
    faUser,
    faXmark,
    faFileContract,
    faChartScatter,
    falCircleExclamation,
    faCircleExclamation,
    faBook
)

export default FontAwesomeIcon
