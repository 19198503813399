import axios from 'axios'
import { downloadFileFromUrl } from '@/composables/helpers.js'

let baseUrl = `api/productalignment`
let baseUrlV2 = `api/v2.0/productalignment`

const abortControllers = []

// Function to abort all ongoing requests
const abortAllRequests = () => {
    abortControllers.forEach((controller) => {
        controller.abort()
    })
}

export function requestProductAlignment(entityPk) {
    return axios.post(`${baseUrl}`, { entityPk: entityPk })
}

export function requestConceptAlignment(conceptText) {
    return axios.post(`${baseUrl}`, { conceptText: conceptText })
}

export function requestInnovationAlignment(entityPk) {
    return axios.post(`${baseUrl}`, {
        entityPk: entityPk,
        productAlignmentType: 'entity_innovation',
    })
}

export function getProductAlignmentRuns(entityPk, override_params = {}) {
    let params = {
        ...{
            entityPk: entityPk,
            SC: ['created_dt'],
            SD: [-1],
            SP: [1],
            FD: [],
            FC: [],
            FT: [],
            FV: [],
            page_size: 20,
            last_row_num: 0,
            page: 1,
        },
        ...override_params,
    }
    return axios.get(baseUrl, { params, paramsSerializer: { indexes: null } })
}

export function getJobRuns(params) {
    return axios.get(baseUrl, { params, paramsSerializer: { indexes: null } })
}

export async function pollForExport(prodAlignmentId, success, failure) {
    let statusResp
    try {
        statusResp = await axios.post(`${baseUrl}/${prodAlignmentId}/export`)
    } catch (err) {
        failure(err)
    }
    if (!statusResp || statusResp.error) {
        return failure()
    }

    switch (statusResp.data.exportStatus) {
        case 'notSet':
            return
        case 'requested':
            return

        case 'complete':
            return success(statusResp.data)

        case 'error':
            return failure(statusResp.data)

        default:
            return failure(statusResp.data)
    }
}

export async function getJobData(prodAlignmentId, baseQuery) {
    let statusResp
    if (baseQuery) {
        statusResp = await axios.get(`${baseUrl}/${prodAlignmentId}`)
    } else {
        let filingYear = new Date().getFullYear() - 4
        statusResp = await axios.get(`${baseUrl}/${prodAlignmentId}`, {
            params: {
                include_target_counts: 'true',
                minimum_filing_year: filingYear,
            },
        })
    }
    return statusResp
}

export async function pollForJobData(
    prodAlignmentId,
    baseQuery,
    success,
    failure,
    progress = () => {},
    finalStatus = 'reportComplete'
) {
    let statusResp
    try {
        statusResp = await getJobData(prodAlignmentId, baseQuery)
    } catch (err) {
        failure(err)
    }

    if (!statusResp || statusResp.error) {
        return failure()
    }
    switch (statusResp.data.status) {
        case finalStatus:
            return success(statusResp.data)
        case 'processing':
            return progress(statusResp.data.percentComplete)
        case 'nodesComplete':
            return progress(statusResp.data.percentComplete)
        case 'countsComplete':
            return
        case 'clusteringComplete':
            return
        case 'error':
            return failure(statusResp.data)

        default:
            return failure(statusResp.data)
    }
    return
}

export async function getClusterBoardData(productAlignmentId) {
    let presignedURL = await axios.get(`${baseUrl}/${productAlignmentId}/cluster_report`)
    return await downloadFileFromUrl(presignedURL.data.presigned_url)
}

export function getProductAlignmentSummary(productAlignmentId, nodeIds) {
    const params = {
        nodeIds: nodeIds,
    }
    return axios.post(`${baseUrl}/${productAlignmentId}/summary_report`, params)
}

export function getInnovationTalent(productAlignmentId) {
    return axios.get(`${baseUrl}/${productAlignmentId}/net_inventor_experience`)
}

export function getInnovationInvestment(productAlignmentId) {
    return axios.get(`${baseUrl}/${productAlignmentId}/innovation_investment`)
}

export function getReturnOnInnvoation(productAlignmentId, entityList) {
    let params = {
        competitorPks: entityList,
    }
    return axios.post(`${baseUrl}/${productAlignmentId}/return_on_innovation`, params)
}

export function getInvestmentStrategy(productAlignmentId) {
    return axios.get(`${baseUrlV2}/${productAlignmentId}/tech_investment_strategy_by_year`)
}

export async function getCompetitors(
    productAlignmentId,
    nodeIds,
    watchlistPk,
    groupByUltimateParent = true
) {
    abortAllRequests()
    return new Promise((resolve, reject) => {
        const controller = new AbortController()
        const signal = controller.signal
        abortControllers.push(controller)

        const params = {
            nodeIds: nodeIds,
            datasetPkFilter: watchlistPk,
            groupByUltimateParent: groupByUltimateParent,
        }

        axios
            .post(`${baseUrl}/${productAlignmentId}/competitor_report`, params, { signal })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(error)
                }
            })
    })
}

export function getCompetitorsBreakdown(productAlignmentId, nodeIds, companyIds, watchlistPk) {
    return new Promise((resolve, reject) => {
        const controller = new AbortController()
        const signal = controller.signal
        abortControllers.push(controller)

        const params = {
            nodeIds: nodeIds,
            entityPks: companyIds,
            datasetPkFilter: watchlistPk,
        }
        return axios
            .post(`${baseUrl}/${productAlignmentId}/competitor_report_by_year`, params, { signal })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(error)
                }
            })
    })
}

export function getCompetitorsTimeline(productAlignmentId, nodeIds, companyIds, watchlistPk) {
    return new Promise((resolve, reject) => {
        const controller = new AbortController()
        const signal = controller.signal
        abortControllers.push(controller)

        const params = {
            nodeIds: nodeIds,
            entityPks: companyIds,
            datasetPkFilter: watchlistPk,
        }
        return axios
            .post(`${baseUrl}/${productAlignmentId}/cumulative_patent_summary_by_year`, params, {
                signal,
            })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(error)
                }
            })
    })
}
