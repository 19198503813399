import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { logger } from '@moatmetrics/vue-logger'
import { createReport, patchReport } from '@/api/reports'

import { useRouter } from 'vue-router'
import { useAuth } from '@/auth/authPlugin'
import { useEntityStore, useMoat2ProductStore, useArenaOutputStore } from '@/stores'

export const useReportGenerationStore = defineStore('reportGeneration', () => {
    const moat2ProductStore = useMoat2ProductStore()
    const entityStore = useEntityStore()
    const arenaOutputStore = useArenaOutputStore()
    const { user } = useAuth()
    const router = useRouter()
    const generateLoading = ref(false)

    const reportName = ref(null)
    const reportTypeSelected = ref(null)
    const reportSelections = ref([])
    const trackStepsInit = ref([
        {
            stepName: 'Select Report Type',
            isCurrentStep: true,
            isCompletedStep: false,
            stepNum: 1,
        },
        {
            stepName: 'Report steps appear below',
            stepDesc: 'The steps belove will be tailored based on your selected report type.',
        },
        {
            stepName: 'Placeholder Label',
            stepDesc:
                'Quis esse nostrud magna velit deserunt consectetur ullamco adipisicing velit mollit ad pariatur dolore in.',
            redacted: true,
        },
    ])
    const trackStepsCompetitiveAnalysis = ref([
        {
            stepName: 'Target Company',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Select Technology Areas',
            stepDesc:
                'Choose the technology areas that matter most for your analysis. Your selections will customize the Moat2Product Report to focus on relevant IP and product alignments. Identify key areas to drive your strategic decision-making.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 3,
        },
        {
            stepName: 'Select Contenders Source',
            stepDesc:
                'Identify the competitors you want to compare against your Target Company. These contenders will be analyzed to highlight competitive differences and opportunities within your selected technology areas.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 4,
        },
        {
            stepName: 'Report Configuration',
            stepDesc:
                "Customize your report settings and preferences. Choose how you want your report formatted, the metrics included, and how you'd like it delivered. Tailor the report to fit your needs for maximum insight.",
            isCurrentStep: false,
            isCompletedStep: true,
            stepNum: 5,
        },
    ])
    const trackStepsHeadToHead = ref([
        {
            stepName: 'Target Company',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 2,
        },
        {
            stepName: 'Select Technology Areas',
            stepDesc:
                'Choose the technology areas that matter most for your analysis. Your selections will customize the Moat2Product Report to focus on relevant IP and product alignments. Identify key areas to drive your strategic decision-making.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 3,
        },
        {
            stepName: 'Select Contenders',
            stepDesc:
                'Identify the competitors you want to compare against your Target Company. These contenders will be analyzed to highlight competitive differences and opportunities within your selected technology areas.',
            isCurrentStep: false,
            isCompletedStep: false,
            stepNum: 4,
        },
        {
            stepName: 'Report Configuration',
            stepDesc:
                "Customize your report settings and preferences. Choose how you want your report formatted, the metrics included, and how you'd like it delivered. Tailor the report to fit your needs for maximum insight.",
            isCurrentStep: false,
            isCompletedStep: true,
            stepNum: 5,
        },
    ])
    const trackStepsFinal = ref([])

    // 'compAnalysis' reportTypeSelected specific
    const contenderSource = ref(null)
    const shouldFillContenders = ref(false)
    const shouldUpdateContenderList = ref(false)
    const shouldShowContenderSelection = ref(false)
    const selectionSource = ref(null)
    const lockCompanySelection = ref(false)

    const resetState = () => {
        generateLoading.value = false
        reportSelections.value = []
        trackStepsInit.value[0].isCurrentStep = true
        trackStepsInit.value[0].isCompletedStep = false
        trackStepsFinal.value = []
        contenderSource.value = null
        shouldFillContenders.value = false
        shouldShowContenderSelection.value = false
        shouldUpdateContenderList.value = false
        selectionSource.value = null
        reportTypeSelected.value = null
        trackStepsCompetitiveAnalysis.value.forEach((step) => {
            step.isCurrentStep = false
            step.isCompletedStep = false
        })
    }

    const getSelectedReportTypeSteps = () => {
        if (reportSelections.value[0]?.value === 'compAnalysis') {
            return trackStepsCompetitiveAnalysis.value
        } else if (reportSelections.value[0]?.value === 'headToHead') {
            return trackStepsHeadToHead.value
        } else {
            return trackStepsCompetitiveAnalysis.value
        }
    }

    const nextButtonDisabled = computed(() => {
        // If report type is not selected (step 1)
        if (!reportTypeSelected.value) {
            return true
        }

        // All steps are set
        if (trackStepsFinal.value.length > 0 && !isReportConfigComplete.value) {
            const currentStep = getCurrentStep()
            const correspondingReportSelection = reportSelections.value.find((selection) => {
                return selection.stepNum === currentStep.stepNum
            })

            if (reportTypeSelected.value === 'compAnalysis') {
                // Edge case for this type - step within a step
                if (contenderSource.value) {
                    return false
                }
                // If correspoding report selection does not exist
                // Or If the step the user is on doesn't have a corresponding report selection value
                // Or if that value doesn't have a length
                if (
                    !correspondingReportSelection ||
                    !correspondingReportSelection.value ||
                    correspondingReportSelection.value.length === 0
                ) {
                    return true
                }
            }

            if (reportTypeSelected.value === 'headToHead') {
                // If correspoding report selection does not exist
                // Or If the step the user is on doesn't have a corresponding report selection value
                // Or if that value doesn't have a length
                if (
                    !correspondingReportSelection ||
                    !correspondingReportSelection.value ||
                    correspondingReportSelection.value.length === 0
                ) {
                    return true
                }
            }
        }

        return false
    })

    const isReportConfigComplete = computed(() => {
        let isComplete = true

        trackStepsFinal.value.forEach((step) => {
            if (!step.isCompletedStep) {
                isComplete = false
            }
        })

        return isComplete
    })

    const getReportSelectionByStep = (stepNum) => {
        return reportSelections.value.find((s) => s.stepNum === stepNum)
    }

    const setReportCreationType = (type, label) => {
        reportTypeSelected.value = type
        reportSelections.value.unshift({
            value: type,
            selectionLabel: label,
            stepNum: 1,
        })

        if (reportTypeSelected.value === 'headToHead') {
            forwardTargetCompanyContenderSelections(true)
        }
    }

    const setReportCompanySelection = (value) => {
        const currentStep = getCurrentStep()
        const findSelection = getReportSelectionByStep(currentStep.stepNum)
        if (!findSelection) {
            reportSelections.value.push({
                value: value.aon_entity_pk,
                selectionLabel: value.name || value.aonEntityName,
                stepNum: currentStep.stepNum,
                allowEdit: true,
            })
        } else {
            findSelection.value = value.aon_entity_pk
            findSelection.selectionLabel = value.name || value.aonEntityName
        }
    }

    const getCurrentStep = () => {
        return trackStepsFinal.value.find((s) => s.isCurrentStep)
    }

    const setCurrentStep = (stepNum) => {
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
        trackStepsFinal.value[stepNum - 1].isCurrentStep = true
        trackStepsFinal.value[stepNum - 1].isCompletedStep = false

        if (stepNum === 4) {
            shouldShowContenderSelection.value = false
        }
    }

    const setCompletedStep = () => {
        const currentStep = getCurrentStep()
        const currentReportTypeSteps = getSelectedReportTypeSteps()
        if (!currentStep) {
            trackStepsInit.value[0].isCurrentStep = false
            trackStepsInit.value[0].isCompletedStep = true
            trackStepsFinal.value = [trackStepsInit.value[0], ...currentReportTypeSteps]

            if (reportSelections.value[1]) {
                trackStepsFinal.value[2].isCurrentStep = true
                trackStepsFinal.value[1].isCompletedStep = true
            } else {
                trackStepsFinal.value[1].isCurrentStep = true
                trackStepsFinal.value[0].isCompletedStep = true
            }
        } else {
            if (reportTypeSelected.value === 'compAnalysis') {
                const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
                trackStepsFinal.value[currentStepIndex].isCurrentStep = false
                trackStepsFinal.value[currentStepIndex].isCompletedStep = true

                // Don't love this, need to make contendersource it's own step?
                if (contenderSource.value !== null) {
                    // if (!reportSelections.value[3]) {
                    //     forwardTargetCompanyContenderSelections()
                    // }

                    if (contenderSource.value !== 'auto') {
                        trackStepsFinal.value[currentStepIndex].isCurrentStep = true
                        trackStepsFinal.value[currentStepIndex].isCompletedStep = false
                        if (!shouldShowContenderSelection.value) {
                            toggleContenderSelection()
                            return
                        }
                        trackStepsFinal.value[currentStepIndex].isCurrentStep = false
                        trackStepsFinal.value[currentStepIndex].isCompletedStep = true
                        trackStepsFinal.value[currentStepIndex + 1].isCompletedStep = true
                    }
                }

                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
            }

            if (reportTypeSelected.value === 'headToHead') {
                const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)
                trackStepsFinal.value[currentStepIndex].isCurrentStep = false
                trackStepsFinal.value[currentStepIndex].isCompletedStep = true

                trackStepsFinal.value[currentStepIndex + 1].isCurrentStep = true
            }
        }
    }

    const gotoPreviousStep = () => {
        const currentStep = getCurrentStep()
        const currentStepIndex = trackStepsFinal.value.findIndex((s) => s.isCurrentStep)

        if (reportTypeSelected.value === 'compAnalysis') {
            if (shouldShowContenderSelection.value && currentStepIndex === 3) {
                shouldShowContenderSelection.value = false
            } else {
                currentStep.isCurrentStep = false
                trackStepsFinal.value[currentStepIndex - 1].isCurrentStep = true
            }
            trackStepsFinal.value[currentStepIndex - 1].isCompletedStep = false
        }
    }

    const generateReport = async () => {
        generateLoading.value = true

        const params = {
            report_type:
                reportTypeSelected.value === 'compAnalysis' ? 'product_alignment' : 'head_to_head',
            report_name: reportName.value
                ? reportName.value
                : reportSelections.value[1].selectionLabel,
            report_description: '',
            requested_application: 'ip_alpha',
            report_input: {},
            requested_user: user.value.id,
        }

        if (reportTypeSelected.value === 'compAnalysis') {
            params.report_input = {
                product_alignment_id:
                    selectionSource.value === 'moat2Product'
                        ? moat2ProductStore.productAlignmentId
                        : arenaOutputStore.outputJobPk,
                global_contender_list: reportSelections.value[3].value.map(
                    (contender) => contender.aonEntityPk
                ),
                global_fill_contenders: shouldFillContenders.value,
                tech_area_contender_list: [
                    {
                        node_ids: reportSelections.value[2].value.map(
                            (techArea) => techArea.nodeId
                        ),
                    },
                ],
            }
        }

        if (reportTypeSelected.value === 'headToHead') {
            params.report_input = {
                product_alignment_id:
                    selectionSource.value === 'moat2Product'
                        ? moat2ProductStore.productAlignmentId
                        : arenaOutputStore.outputJobPk,
                target_entity_pk: reportSelections.value[1].value,
                contender_ids: reportSelections.value[3].value.map(
                    (contender) => contender.aonEntityPk
                ),
                node_ids: reportSelections.value[2].value.map((techArea) => techArea.nodeId),
            }
        }

        try {
            const { data } = await createReport(params)
            submitReportForGeneration(data)
        } catch (err) {
            logger.error(err)
        }
    }

    const submitReportForGeneration = async (reportData) => {
        const params = {
            report_action: 'submit',
        }

        try {
            await patchReport(reportData.report_pk, params)
        } catch (err) {
            logger.error(err)
        } finally {
            generateLoading.value = false
            router.push({ name: 'Reports' })
        }
    }

    const forwardTargetCompanySelections = (source) => {
        selectionSource.value = source
        const store = source === 'moat2Product' ? moat2ProductStore : arenaOutputStore
        const entity =
            source === 'moat2Product'
                ? store.productAlignmentTopCompetitors[0]
                : store.targetCompany[0]
        reportSelections.value.push(
            {
                value: entity.aonEntityPk,
                selectionLabel: entity.name || entity.aonEntityName,
                stepNum: 2,
            },
            {
                value:
                    source === 'moat2Product'
                        ? [...store.productAlignmentTechAreaSelections]
                        : [...store.arenaOutputTechAreaSelections],
                selectionLabel: ' Technology Areas Selected',
                stepNum: 3,
                allowEdit: true,
            }
        )
        lockCompanySelection.value = true
    }

    const forwardTargetCompanyContenderSelections = (targetOnly = false) => {
        const contenderSelection = {
            value: !targetOnly
                ? moat2ProductStore.productAlignmentTopCompetitors
                : [moat2ProductStore.productAlignmentTopCompetitors[0]],
            selectionLabel: ' Contenders Selected',
            stepNum: 4,
            allowEdit: true,
        }

        const existingIndex = reportSelections.value.findIndex((item) => item.stepNum === 4)
        existingIndex !== -1
            ? (reportSelections.value[existingIndex] = contenderSelection)
            : reportSelections.value.push(contenderSelection)
    }

    const setShouldFillContenders = () => {
        shouldFillContenders.value = !shouldFillContenders.value
    }

    const toggleContenderSelection = () => {
        shouldShowContenderSelection.value = !shouldShowContenderSelection.value
    }

    const flagForContenderUpdate = () => {
        shouldUpdateContenderList.value = true
    }

    const onTechAreaChange = () => {
        contenderSource.value = null
        trackStepsFinal.value[3].isCompletedStep = false
        trackStepsFinal.value[3].value = []
    }

    return {
        generateLoading,
        resetState,
        trackStepsInit,
        trackStepsCompetitiveAnalysis,
        trackStepsFinal,
        contenderSource,
        lockCompanySelection,
        reportName,
        reportTypeSelected,
        reportSelections,
        setReportCreationType,
        getSelectedReportTypeSteps,
        setCurrentStep,
        getCurrentStep,
        gotoPreviousStep,
        getReportSelectionByStep,
        nextButtonDisabled,
        setCompletedStep,
        forwardTargetCompanySelections,
        forwardTargetCompanyContenderSelections,
        setShouldFillContenders,
        toggleContenderSelection,
        shouldShowContenderSelection,
        flagForContenderUpdate,
        shouldUpdateContenderList,
        selectionSource,
        isReportConfigComplete,
        generateReport,
        onTechAreaChange,
        setReportCompanySelection,
    }
})
