<template>
    <div class="decreasing-innovation">
        <AonCard
            style="position: relative"
            class="mt-4"
            title="Decreasing Innovation Investment"
            desc="This section identifies technology areas where the company’s innovation activity is slowing down. These areas show a decline in patent filings, signaling reduced focus or resource allocation."
            :borders="false"
        >
            <AonCoverLoading
                :loading="loadingData"
                title="Loading Decreasing Innovations..."
            ></AonCoverLoading>
            <div class="content-holder">
                <MoatTable
                    style="width: 100%; height: 261px"
                    :class="themeClass"
                    :column-defs="colDefs"
                    :suppress-auto-size="true"
                    :row-data="tableData"
                    :tooltip-interaction="true"
                    :tooltip-mouse-track="true"
                    :tooltip-show-delay="500"
                    tooltip-show-mode="whenTruncated"
                    @grid-ready="gridInit"
                />
            </div>
        </AonCard>
    </div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useMoat2ProductStore } from '@/stores'
import { getInnovationInvestment } from '@/api/productAlignment.js'

import { useI18n } from 'vue-i18n'
import { AgGridVue as MoatTable } from '@ag-grid-community/vue3'

const logger = inject('logger')
const moat2ProductStore = useMoat2ProductStore()
const { t } = useI18n()

const loadingData = ref(true)
const tableData = ref([])

const gridApi = ref(null)
const themeClass = ref('ag-theme-quartz')
const colDefs = ref([
    {
        field: 'technology',
        flex: 2,
    },
    {
        field: 'targetEntityPatentCount',
        headerTooltip: 'Patent Count',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.patentCount'),
            headerName: 'Patent Count',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.targetEntityPatentCount
                    ? p.data.targetEntityPatentCount.toLocaleString()
                    : '--'
            }
        },
    },
    {
        field: 'opportunity',
        headerTooltip: 'Momentum',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.momentum'),
            headerName: 'Momentum',
        },
        flex: 1,
        valueFormatter: (p) => {
            if (p.data) {
                return p.data.opportunity.toFixed(2)
            }
        },
    },
    {
        field: 'opportunityBenchmarkPercentDelta',
        headerTooltip: 'Innovation Gap',
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.innovationGap'),
            headerName: 'Innovation Gap',
        },
        flex: 1,
        cellRenderer: 'MTCustomCellScoreChange',
        cellRendererParams: {
            colorValue: true,
        },
        valueFormatter: (p) => {
            if (p.data) {
                return `${p.data.opportunityBenchmarkPercentDelta.toFixed()}%`
            }
        },
    },
    {
        headerComponent: 'MTCustomColHeader',
        headerComponentParams: {
            tooltipDefintion: t('productAlignment.strategyTooltips.ipRepoCost'),
            headerName: 'IP Reproduction Cost',
        },
        field: 'ipRebuildCostBin',
        flex: 1,
    },
])

onMounted(() => {
    getTopInnovations()
})

const gridInit = (params) => {
    gridApi.value = params.api
    params.api.hideOverlay()
}

const getTopInnovations = async () => {
    loadingData.value = true
    try {
        let { data } = await getInnovationInvestment(moat2ProductStore.productAlignmentId)
        tableData.value = data['decreasingInnovationInvestments']
    } catch (error) {
        logger.error(error)
    } finally {
        loadingData.value = false
    }
}
</script>

<style lang="scss" scoped>
.decreasing-innovation {
    width: 100%;
    position: relative;
}
</style>
