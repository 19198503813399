<template>
    <div id="aon-app" ref="captureRef">
        <div class="main-content">
            <AonApplicationHeader
                v-show="showNav"
                v-bind="$attrs"
                id="2e2-navigation"
                :user="{ name: user?.email }"
                title=""
                :nav-items="navItems"
                aon-logo-named-route="Home"
                @select-option="handleDropdownSelect"
                style="z-index: 11 !important"
                dark-mode
            >
                <template #headerImage>
                    <img
                        style="height: 40px; margin-top: -2px"
                        :src="logoImport"
                        alt="Moat Metrics Home"
                    />
                </template>
                <template #rightAppend>
                    <div class="global-search-holder">
                        <GlobalSearch style="min-width: 500px" ignore-enter />
                    </div>
                </template>
            </AonApplicationHeader>
            <router-view />
        </div>
        <AppFooter v-if="isAuthenticated" />
        <Snackbar />
        <AonModal
            v-if="showGlobalModal"
            :title="globalModalObj.title"
            message=""
            col-width="4"
            @close="closeModal()"
        >
            <div class="modal-content">
                <div v-if="globalModalObj.definition" class="definition mb-4">
                    <h6 class="bold navy01--text mb-1">Definition</h6>
                    <p>{{ globalModalObj.definition }}</p>
                </div>
                <div v-if="globalModalObj.moreInfo" class="more-info mb-4">
                    <h6 class="bold navy01--text mb-1">Why this is important</h6>
                    <p>{{ globalModalObj.moreInfo }}</p>
                </div>
                <div v-if="globalModalObj.zScoreInfo" class="score-info">
                    <h6 class="bold navy01--text mb-1">What is a clipped z-score</h6>
                    <p>{{ globalModalObj.zScoreInfo }}</p>
                </div>
            </div>
        </AonModal>
        <ScreenersModal v-if="showScreeners" />
        <!-- <div v-if="showNav" :class="['feedback']">
            <ApplicationFeedback :capture="captureRefFunc()" :user="user" />
        </div> -->
        <EditWatchlistModal v-if="datasetToEdit" />
        <CompanyMarketSummary />
        <WatchlistAddOrCreateModal v-if="idsToAdd || datasetStore.showOnlyCreateWatchlists" />
    </div>
</template>

<script setup>
import { ref, computed, watch, inject } from 'vue'
import { RouterView, useRoute, useRouter } from 'vue-router'
import {
    useAppStore,
    useScreenerStore,
    useDatasetStore,
    useParticipatingMarketsStore,
    useEntityStore,
} from '@/stores'

import { storeToRefs } from 'pinia'
import { useI18n } from 'vue-i18n'
import { hideAllPoppers } from 'floating-vue'
import logoImport from '@/assets/moat_alpha.png'

import { AonApplicationHeader } from '@moatmetrics/armory/src/components'
import GlobalSearch from '@/components/GlobalSearch'
import AppFooter from './components/AppFooter'
import Snackbar from './components/Snackbar'
import ScreenersModal from '@/components/screeners/ScreenersModal'

import WatchlistAddOrCreateModal from '@/components/Watchlists/WatchlistActions/WatchlistAddOrCreateModal.vue'
import EditWatchlistModal from '@/components/Watchlists/WatchlistActions/EditWatchlistModal.vue'
import CompanyMarketSummary from '@/components/helpers/CompanyMarketSummary.vue'
import { useFlag, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-vue'
import { useAuth } from '@/auth/authPlugin'

const { flagsReady } = useFlagsStatus()
const updateUnleashContext = useUnleashContext()
const { t } = useI18n()

const datasetStore = useDatasetStore()
const { idsToAdd, datasetToEdit } = storeToRefs(datasetStore)

const appStore = useAppStore()
const { showGlobalModal, globalModalObj, showTOS } = storeToRefs(appStore)
const { user, tosAccepted, isAuthenticated, logout } = useAuth()

const participatingMarketsStore = useParticipatingMarketsStore()
const entityStore = useEntityStore()
const router = useRouter()
const route = useRoute()
const captureRef = ref(null)
const rumAgent = inject('rumAgent')
// const showTOSFlag = useFlag('ipAlpha.showTOS')

const navItems = ref([
    {
        type: 'links',
        links: [
            {
                linkLabel: t('headerNav.nav.companies'),
                linkNamedRoute: 'Companies',
            },
            {
                linkLabel: t('headerNav.nav.markets'),
                linkNamedRoute: 'Markets',
            },
            {
                linkLabel: t('headerNav.nav.arenas'),
                linkNamedRoute: 'Arena List View',
            },
            {
                linkLabel: t('headerNav.nav.watchLists'),
                linkNamedRoute: 'Watchlist List',
            },
        ],
    },
    {
        type: 'dropdowns',
        links: [
            {
                iconOptions: {
                    iconName: 'fa-info-circle',
                    iconStyle: 'fas',
                    iconColor: 'grey03',
                },
                ignorePreset: true,
                dropdownPos: 'right',
                triggerBorder: false,
                borderColor: 'transparent',
                showDropDownArrow: false,
                triggerLabel: ' ',
                items: [
                    {
                        itemText: t('headerNav.support.email'),
                        itemValue: 'support-email',
                        iconOptions: {
                            iconName: 'fa-envelope',
                            iconStyle: 'fas',
                            iconColor: 'grey03',
                        },
                    },
                ],
            },
            {
                iconOptions: {
                    iconName: 'fa-user',
                    iconStyle: 'fas',
                    iconColor: 'grey03',
                },
                ignorePreset: true,
                dropdownPos: 'right',
                triggerBorder: false,
                borderColor: 'transparent',
                showDropDownArrow: false,
                triggerLabel: ' ',
                items: [
                    {
                        itemText: t('headerNav.user.editUser'),
                        itemValue: 'Account Profile',
                        iconOptions: {
                            iconName: 'fa-user',
                            iconStyle: 'fas',
                            iconColor: 'grey03',
                        },
                    },
                    {
                        itemText: t('headerNav.user.signOut'),
                        itemValue: 'Logout',
                        iconOptions: {
                            iconName: 'fa-arrow-left-from-line',
                            iconStyle: 'fas',
                            iconColor: 'grey03',
                        },
                    },
                ],
            },
        ],
    },
])

const entityName = computed(() => {
    return entityStore.entity ? entityStore.entity.name : ''
})

watch(
    () => router,
    (newVal) => {
        if (newVal.currentRoute.value.meta.tabName) {
            document.title = `Alpha ${newVal.currentRoute.value.meta.tabName ? '- ' + newVal.currentRoute.value.meta.tabName : ''}`
        }
    },
    { flush: 'pre', immediate: true, deep: true }
)

watch(
    () => entityName.value,
    (newVal) => {
        document.title = `Alpha - ${newVal}`
    }
)

// watch(showGlobalModal, (val) => {
//     if (val) {
//         hideAllPoppers()
//     }
// })

watch(showTOS, (val) => {
    if (val) {
        hideAllPoppers()
    }
})

watch(flagsReady, () => {
    navItems.value
        .find((item) => item.type === 'links')
        .links.splice(3, 0, {
            linkLabel: 'Reports',
            linkNamedRoute: 'Reports',
        })

    // if (showTOSFlag.value) {
    //     navItems.value[1].links[0].items.push({
    //         itemText: t('headerNav.support.tos'),
    //         itemValue: 'support-tos',
    //         iconOptions: {
    //             iconName: 'fa-file-contract',
    //             iconStyle: 'fas',
    //             iconColor: 'grey03',
    //         },
    //     })
    // }
})

watch(
    user,
    async () => {
        if (user.value?.id) {
            await updateUnleashContext({
                userId: user.value.email,
            })
            rumAgent.setUser(user.value.id, user.value.org_id)
        }
    },
    { immediate: true }
)

const handleDropdownSelect = async (params) => {
    if (params.itemValue === 'support-line') {
        window.open(`tel:${t('headerNav.support.phoneNumber')}`)
    }

    if (params.itemValue === 'support-email') {
        window.open(`mailto:${t('headerNav.support.email')}`)
    }

    if (params.itemValue === 'support-tos') {
        appStore.setShowTOS(true)
    }

    if (params.itemValue === 'Account Profile') {
        router.push({ name: 'Account Profile' })
    }

    if (params.itemValue === 'Logout') {
        await logout({
            async openUrl() {
                window.location.href = document.location.origin
            },
        })
    }
}

const closeModal = () => {
    appStore.setShowGlobalModal(false)
}

// const watchlistToBeEdited = computed(() => {
//     return watchlistStore.screenersModalOpen
// })

const showScreeners = computed(() => {
    let screenerStore = useScreenerStore()
    return screenerStore.screenersModalOpen
})

const footerOverride = computed(() => {
    return appStore.pushFooter
})

const showNav = computed(() => {
    if (!isAuthenticated.value || route.meta.fullScreen) {
        return false
    }

    return true
})

const captureRefFunc = () => {
    return captureRef.value
}
</script>

<style lang="scss">
@import '@moatmetrics/armory/src/sass/main.scss';
@import '@/styles/main.scss';

@import '@moatmetrics/armory/src/sass/responsive.scss';

.aon-container {
    max-width: none;

    @include overSized {
        max-width: none;
    }
}

#aon-app {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .main-content {
        width: 100%;
        height: 100%;
        min-height: calc(100vh - 190px); // height of footer
        background: $grey05;
        position: relative;
        z-index: 1;
    }
}

.table-aips-latest {
    position: relative;
    z-index: 0;
}

.feedback {
    position: fixed;
    z-index: 10010;
    bottom: 30px;
    right: 30px;
}
</style>
