<template>
    <div class="report-generation-container">
        <AonSpinner v-if="loading" class="ma-auto" :scale="0.5" />
        <div class="parent-holder d-flex" v-else>
            <StepTracker
                overwrite-class="no-substeps"
                allow-step-navigation
                heading="Create a new Report"
                allow-collapse
            />
            <KeepAlive>
                <div
                    v-if="
                        reportGenerationStore.trackStepsFinal.length === 0 ||
                        reportGenerationStore.trackStepsFinal[0].isCurrentStep
                    "
                    class="w-full pa-5"
                >
                    <h5>Select Report Type</h5>
                    <AonCard class="mt-5">
                        <div class="content-holder">
                            <ReportTypeSelection />
                        </div>
                    </AonCard>
                </div>
            </KeepAlive>

            <div v-if="showCompAnalysisReport" class="competitive-analysis-type w-full pa-5">
                <CompetitiveAnalysisReport />
            </div>
            <div v-if="showHeadToHeadReport" class="competitive-analysis-type w-full pa-5">
                <HeadToHeadReport />
            </div>
        </div>
        <AonContainer>
            <AonRow style="height: 75px">
                <AonCol class="aon-col-12 pa-0">
                    <ReportNav />
                </AonCol>
            </AonRow>
        </AonContainer>
    </div>
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted } from 'vue'
import { useReportGenerationStore } from '@/stores'
import { useRouter } from 'vue-router'

import StepTracker from '@/components/reports/shared/StepTracker.vue'
import ReportNav from '@/components/reports/shared/ReportNav.vue'
import CompetitiveAnalysisReport from '@/components/reports/reportTypes/competitiveAnalysis/CompetitiveAnalysisReport.vue'
import HeadToHeadReport from '@/components/reports/reportTypes/headToHead/HeadToHeadReport.vue'

import ReportTypeSelection from '@/components/reports/shared/ReportTypeSelection.vue'

const reportGenerationStore = useReportGenerationStore()
const router = useRouter()

const loading = ref(true)

onBeforeMount(async () => {
    loading.value = false
})

onMounted(() => {
    checkForCompanyData()
})

const showCompAnalysisReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'compAnalysis' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const showHeadToHeadReport = computed(() => {
    return (
        reportGenerationStore.reportTypeSelected === 'headToHead' &&
        reportGenerationStore.trackStepsFinal.length > 0 &&
        !reportGenerationStore.trackStepsFinal[0].isCurrentStep
    )
})

const checkForCompanyData = () => {
    if (reportGenerationStore.reportSelections.length === 0) {
        router.push({ name: 'Reports' })
    }
}
</script>

<script>
export default {
    name: 'CreateReport',
}
</script>

<style lang="scss" scoped>
.report-generation-container {
    height: 100%;
    width: 100%;

    .parent-holder {
        height: calc(100% - 78px);
    }

    .content-holder {
        height: 100%;
    }

    .ignore-max-width {
        max-width: none !important;
    }

    .prevent-content-overflow {
        max-height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    }
}
</style>
