<template>
    <div class="company-summary-tab mb-5">
        <AonSpinner
            v-if="entityStore.loadingEntity || !entityStore.entity"
            class="ma-auto"
            :scale="0.5"
            color="castleMoat"
        />
        <div v-else class="card-holder">
            <SummaryCard />
            <ExecutiveTeamCard />
            <FundingCard v-if="entityStore.entity.stock_ticker" />
            <FundingRoundsCard />
        </div>
    </div>
</template>

<script setup>
import { useEntityStore } from '@/stores'
import SummaryCard from './SummaryCard.vue'
import FundingCard from './FundingCard.vue'

import ExecutiveTeamCard from '@/components/company/tabs/execution/ExecutiveTeamCard.vue'
import FundingRoundsCard from '@/components/company/tabs/valuations/FundingRoundsCard.vue'

const entityStore = useEntityStore()
</script>

<style lang="scss" scoped>
.company-summary-tab {
    width: 100%;
    position: relative;
}
</style>
