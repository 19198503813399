import { ERROR, NOT_FOUND } from '@/lib/constants/route-names'
import { ACCOUNT_PROFILE, LOGOUT, LOGIN_CALLBACK } from '@/lib/constants/auth-routes'

import Account from '@/views/auth/Account'
import Home from '@/views/Home'
import Companies from '@/views/Companies'
import Markets from '@/views/Markets'
import WatchlistsListView from '@/views/WatchlistsListView'
// import Watchlist from '@/views/Watchlist'
import WatchlistView from '@/views/WatchlistView'
import CreateReport from '@/views/CreateReport'
import Error from '@/views/Error'
import NotFound from '@/views/404'
import ReportsView from '@/views/ReportsView'
import ArenaOutputView from '@/views/ArenaOutputView'
import ArenaView from '@/views/ArenaView'
import ArenaListView from '@/views/ArenaListView'
import Research from '@/views/Research'
import LoginCallback from '@/views/auth/LoginCallback.vue'
import { authGuard } from '@auth0/auth0-vue'

export const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        props: { initTab: '1' },
        beforeEnter: authGuard,
        meta: { tabName: 'Home' },
    },
    {
        path: '/companies/:screenerId?',
        name: 'Companies',
        component: Companies,
        beforeEnter: authGuard,
        meta: { tabName: 'Company Search' },
    },
    {
        path: '/markets/:screenerId?',
        name: 'Markets',
        component: Markets,
        beforeEnter: authGuard,
        meta: { tabName: 'Markets Search' },
    },
    {
        path: '/research-company/:targetPk',
        name: 'Research Company',
        component: Research,
        meta: { requiresAuth: true },
        query: { tabName: null },
        beforeEnter: authGuard,
    },
    {
        path: '/research-market/:targetPk',
        name: 'Research Market',
        component: Research,
        meta: { requiresAuth: true },
        query: { tabName: null },
        beforeEnter: authGuard,
    },
    {
        path: '/watchlist-list',
        name: 'Watchlist List',
        component: WatchlistsListView,
        beforeEnter: authGuard,
        meta: { tabName: 'Watchlist List' },
    },
    {
        path: '/create-report',
        name: 'Create Report',
        component: CreateReport,
        beforeEnter: authGuard,
        query: { reportPk: null, watchlistPk: null, source: null },
        meta: { tabName: 'Create Report' },
    },
    {
        path: '/watchlist/:datasetPk',
        name: 'Watchlist',
        component: WatchlistView,
        beforeEnter: authGuard,
        meta: { tabName: 'Watchlist' },
    },
    {
        path: '/error',
        name: ERROR,
        component: Error,
        meta: { fullScreen: true },
    },
    {
        path: '/loginCallback',
        name: LOGIN_CALLBACK,
        component: LoginCallback,
    },
    {
        path: '/logout',
        name: LOGOUT,
    },
    {
        path: '/account',
        name: ACCOUNT_PROFILE,
        component: Account,
        beforeEnter: authGuard,
    },
    {
        path: '/404',
        name: NOT_FOUND,
        component: NotFound,
        meta: { fullScreen: true },
    },
    {
        path: '/reports',
        name: 'Reports',
        component: ReportsView,
        meta: { requiresAuth: true, tabName: 'Reports List' },
    },
    {
        path: '/arena/:arenaSourceId?',
        name: 'Arena',
        component: ArenaView,
        meta: { requiresAuth: true, tabName: 'Arena' },
    },
    {
        path: '/arenas',
        name: 'Arena List View',
        component: ArenaListView,
        meta: { requiresAuth: true, tabName: 'Arena List' },
    },
    {
        path: '/arenaOutput/:jobPk?',
        name: 'Tailored Output',
        component: ArenaOutputView,
        meta: { requiresAuth: true, tabName: 'Arena Output' },
    },
    {
        // Keep the catchAll route last
        path: '/:pathMatch(.*)*',
        redirect: { name: 'Not Found' },
    },
]
