<template>
    <div class="highlighter d-flex align-items-center py-3">
        <div class="new-tab-icon px-2" v-tooltip="{ content: 'Open company in a new tab' }">
            <a v-if="props.companyPk" :href="entityRouteLink(props.companyPk)" target="_blank">
                <font-awesome-icon class="grey01--text" icon="fas fa-arrow-up-right-from-square" />
            </a>
        </div>
        <div
            class="company-click-area d-flex align-items-center w-full justify-content-between"
            :class="[{ 'alt-style': props.watchlistPk }]"
            @click.prevent="gotoCompany(props.watchlistPk)"
        >
            <div class="highlight-holder d-flex align-items-center w-full">
                <div class="aligner link">
                    <span
                        v-for="(letter, index) in parsedTargetWords"
                        :key="`word_${index}`"
                        class="align-items-center"
                        :class="getClass(index % 2)"
                    >
                        {{ letter }}
                    </span>
                </div>
                <div class="aligner">
                    <div
                        v-if="ticker"
                        class="ticker d-inline-flex align-items-center ml-2 pa-1 grey05"
                    >
                        <span
                            v-for="(letter, index) in parsedTargetTicker"
                            :key="`ticker_${index}`"
                            :class="getClass(index % 2)"
                        >
                            {{ letter }}
                        </span>
                    </div>
                </div>
                <div
                    v-if="props.watchlistPk && props.companyParams.address"
                    v-tooltip="{ content: props.companyParams.address, distance: 10 }"
                    class="px-2 d-flex align-items-center"
                >
                    <p class="small mr-2">|</p>
                    <p class="w-half overflow-ellipsis small grey01--text" style="width: 350px">
                        <span style="font-weight: 700">Address:</span>
                        {{ props.companyParams.address.replace(/,/g, ', ') }}
                    </p>
                </div>
                <div
                    v-if="props.watchlistPk && props.companyParams.patent_count_us"
                    class="px-2 d-flex align-items-center"
                >
                    <p class="small mr-4">|</p>
                    <p class="w-half small grey01--text" style="white-space: nowrap">
                        <span style="font-weight: 700">Patent Count:</span>
                        {{ parseInt(props.companyParams.patent_count_us).toLocaleString() }}
                    </p>
                </div>
            </div>
            <div
                v-if="props.watchlistPk"
                v-tooltip="{ content: 'Add company to current Watchlist', distance: 10 }"
                class="px-2"
                @click.stop="addToWatchlist()"
            >
                <AonSpinner v-if="loadingAdd" :scale="0.2" />
                <font-awesome-icon v-else class="icon grey01--text" icon="fas fa-circle-plus" />
            </div>
        </div>
        <CompanyPopover
            :show-popover="showPopover"
            :passed-company-details="props.companyParams"
            :popper-options="{ placement: 'right' }"
            @close-popover="showPopover = false"
        />
    </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import { addToDataset } from '../../api/datasets'
import { useAuth } from '@/auth/authPlugin'
import { entityRouteLink } from '../../router/utils'
import CompanyPopover from '../helpers/CompanyPopover.vue'

const router = useRouter()
const emit = defineEmits(['clear'])
const logger = inject('logger')
const eventBus = inject('eventBus')

const props = defineProps({
    targetWords: {
        type: String,
        default: null,
    },
    searchTerm: {
        type: String,
        default: null,
    },
    effect: {
        type: String,
        default: 'highlight',
    },
    // May rethink this in the future - BB
    ticker: {
        type: String,
        default: null,
    },
    companyPk: {
        type: String,
        default: '',
    },
    watchlistPk: {
        type: String,
        default: null,
    },
    companyParams: {
        type: Object,
        default: () => ({}),
    },
})
const showPopover = ref(false)
const loadingAdd = ref(false)

const parsedSearchTerm = computed(() => {
    return '(' + props.searchTerm.trim().replace(/ +/g, '|') + ')'
})

const parsedTargetWords = computed(() => {
    return props.targetWords.split(new RegExp(parsedSearchTerm.value, 'gi'))
})

const parsedTargetTicker = computed(() => {
    return props.ticker.split(new RegExp(parsedSearchTerm.value, 'gi'))
})

const getClass = (i) => {
    let myClass = {}
    myClass[props.effect] = !!i
    return myClass
}

const gotoCompany = (isWatchlist = false) => {
    if (!isWatchlist) {
        emit('clear')
        router.push({
            name: 'Research Company',
            params: { targetPk: props.companyPk },
            query: { tabName: 'profile' },
        })
        return
    }

    if (props.watchlistPk) {
        showPopover.value = true
    }
}

const addToWatchlist = async () => {
    loadingAdd.value = true
    try {
        const { user } = useAuth()
        let data = await addToDataset(props.watchlistPk, user.value.id, 'Entity', [
            { id_value: props.companyParams.aon_entity_pk },
        ])
    } catch (err) {
        logger.error(err)
        throw err
    } finally {
        eventBus.emit('update-watchlist')
        loadingAdd.value = false
    }
}
</script>

<style lang="scss">
.highlighter {
    .highlight {
        background-color: $warningBackground;
        font-weight: 600;

        transition: all ease-in-out 0.3s;
    }

    .aligner {
        white-space: nowrap;
    }

    .ticker {
        border-radius: 4px;

        transition: all ease-in-out 0.3s;

        span {
            color: $grey01;
            transition: all ease-in-out 0.3s;
        }
    }

    .add-icon {
        border-left: solid 1px $grey01;
    }

    &:hover {
        background: $grey05;

        .ticker {
            background: $grey01;

            .highlight {
                color: black;
            }

            span {
                color: white;
            }
        }
    }
}
</style>
