<template>
    <div class="industry-litigation-chart h-full">
        <div id="amChartLitRadar"></div>
        <div class="legend-holder w-full">
            <div class="radar-legend w-full">
                <div class="risk-key d-flex w-full justify-content-center align-items-center">
                    <p>Low Risk</p>
                    <div class="bullet-holder d-flex mx-2">
                        <div
                            v-for="color in riskColorArray"
                            class="risk-bullet mx-1"
                            :style="`background: ${color}`"
                        ></div>
                    </div>
                    <p>High Risk</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import * as am5radar from '@amcharts/amcharts5/radar'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'

import {
    risk1,
    risk2,
    risk3,
    risk4,
    risk5,
    risk6,
    risk7,
    risk8,
    risk9,
    risk10,
} from '@/styles/js_variables.module.scss'

const props = defineProps({
    chartData: {
        type: Array,
        default: () => [],
    },
})

import { config } from '@/config'

let root
let yAxis
let xAxis
let chart

const riskColorArray = ref([risk1, risk5, risk10])
const seriesData = ref([])

onMounted(async () => {
    am5.addLicense(config.license.AMChartsLicense)
    root = am5.Root.new('amChartLitRadar')
    configureData(props.chartData)
})

onUnmounted(() => {
    root.dispose()
})

const configureData = (data) => {
    seriesData.value = data
        .filter((item) => item.nodeType === 'product')
        .map((item) => ({
            category: item.displayName,
            value: item.caseCount,
            risk: item.riskScore,
        }))
    configureChart()
}

const configureChart = () => {
    root.setThemes([am5themes_Animated.new(root)])

    chart = root.container.children.push(
        am5radar.RadarChart.new(root, {
            panX: false,
            panY: false,
            wheelX: 'none',
            wheelY: 'none',
            innerRadius: am5.percent(40),
        })
    )

    const cursor = chart.set(
        'cursor',
        am5radar.RadarCursor.new(root, {
            behavior: 'zoomX',
        })
    )
    cursor.lineY.set('forceHidden', true)

    generateAxes()
}

const generateAxes = () => {
    // X Axis - how the dot travels around the radar in a circle | 0 - 100
    var xRenderer = am5radar.AxisRendererCircular.new(root, {
        minGridDistance: 20,
    })

    xRenderer.grid.template.set('forceHidden', true)
    xRenderer.labels.template.set('forceHidden', true)

    xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
            maxDeviation: 0,
            categoryField: 'category',
            renderer: xRenderer,
        })
    )

    // Y Axis - how the dot travels around the radar in a circle | 0 - 100
    var yRenderer = am5radar.AxisRendererRadial.new(root, {})
    yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: yRenderer,
        })
    )

    // labels
    yRenderer.labels.template.setAll({
        fontSize: 12,
        centerX: am5.percent(50),
        fontWeight: 'bold',
        position: 'absolute',
    })
    // Border/background around labels
    yAxis.get('renderer').labels.template.setup = function (target) {
        target.set(
            'background',
            am5.RoundedRectangle.new(root, {
                fill: am5.color('#fff'),
                stroke: am5.color('#DDD'),
                opacity: 0.8,
            })
        )
    }

    configureSeries()
}

const configureSeries = () => {
    var series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
            sequencedInterpolation: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'value',
            valueField: 'risk',
            categoryXField: 'category',
            tooltip: am5.Tooltip.new(root, {}),
        })
    )
    series.set('stroke', root.interfaceColors.get('background'))

    // Rounded corners for columns
    series.columns.template.setAll({
        cornerRadius: 5,
        tooltipText: '[bold]{categoryX}:[/] {valueY} Cases \n[bold]Risk:[/] {value}',
    })

    // Add heat rules
    series.set('heatRules', [
        {
            target: series.columns.template,
            dataField: 'value',
            customFunction: function (sprite, min, max, value) {
                sprite.set('fill', getHeat(value))
            },
        },
    ])
    // Mock data
    xAxis.data.setAll(seriesData.value)
    series.data.setAll(seriesData.value)

    series
        .get('tooltip')
        .label.set('text', '[bold]{categoryX}:[/] {valueY} Cases \n[bold]Risk:[/] {value}')

    // Animate chart and series in
    series.appear(1000)
    chart.appear(1000, 100)
}

// Keeping this for now as we might use it later - BB
const getHeat = (heat) => {
    if (heat == 'Low') {
        return am5.color(risk1)
    }
    if (heat == 'Medium') {
        return am5.color(risk5)
    }
    if (heat == 'High') {
        return am5.color(risk10)
    }
}
// const getHeat = (heat, am = true) => {
//     if (heat <= 0 && heat <= 10) {
//         return am ? am5.color(risk1) : risk1
//     }
//     if (heat <= 20) {
//         return am ? am5.color(risk2) : risk2
//     }
//     if (heat <= 30) {
//         return am ? am5.color(risk3) : risk3
//     }
//     if (heat <= 40) {
//         return am ? am5.color(risk4) : risk4
//     }
//     if (heat <= 50) {
//         return am ? am5.color(risk5) : risk5
//     }
//     if (heat <= 60) {
//         return am ? am5.color(risk6) : risk6
//     }
//     if (heat <= 70) {
//         return am ? am5.color(risk7) : risk7
//     }
//     if (heat <= 80) {
//         return am ? am5.color(risk8) : risk8
//     }
//     if (heat <= 90) {
//         return am ? am5.color(risk9) : risk9
//     }
//     if (heat <= 100) {
//         return am ? am5.color(risk10) : risk10
//     } else {
//         return am ? am5.color(risk10) : risk10
//     }
// }
</script>
<style lang="scss" scoped>
.industry-litigation-chart {
    width: 100%;
    position: relative;

    #amChartLitRadar {
        width: 100%;
        height: 100%;
        min-height: 100px;
        div {
            height: 100%;
        }
    }

    .legend-holder {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
    }

    .radar-legend {
        .risk-bullet {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }
    }
}
</style>
