<template>
    <div class="output-parent d-flex flex-column h-full">
        <div class="output-header knights-cloak-80 py-4">
            <AonContainer add-page-padding class="output-view">
                <AonRow>
                    <AonCol
                        class="aon-col-12 pa-0 w-full justify-content-between flex-nowrap align-items-center"
                    >
                        <div class="left d-flex align-content-center flex-wrap">
                            <div
                                class="back-holder d-flex align-content-center w-full flex-wrap"
                                @click="goBack()"
                            >
                                <font-awesome-icon
                                    icon="fas fa-chevron-left"
                                    class="white--text align-self-center"
                                    size="lg"
                                />
                                <p class="white--text ml-2 underline">{{ backCopy }}</p>
                            </div>
                            <h4
                                v-if="!arenaStore.boardGlobalDetails"
                                class="white--text w-full overflow-ellipsis mt-2 mb-0 pb-0"
                                v-tooltip="{ content: props.prompt }"
                            >
                                {{ props.prompt }}
                            </h4>
                            <h4 v-else class="white--text w-full overflow-ellipsis mt-2 mb-0 pb-0">
                                {{ arenaStore.boardGlobalDetails.name }}
                            </h4>
                        </div>
                        <div class="button-holder d-flex">
                            <AonButton
                                v-if="showExport"
                                class="mr-4"
                                label="Export Product Patent Portfolio"
                                text-with-icon
                                :loading="exportLoading"
                                :disabled="
                                    !arenaStore.boardGlobalDetails || loadingMaterialComponents
                                "
                                :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                                @clicked="exportPatents"
                            />
                            <AonButton
                                label="Generate Report"
                                text-with-icon
                                :loading="generateLoading || allowReportGen"
                                :disabled="allowReportGen"
                                :tooltip-text="
                                    allowReportGen ? 'Please wait while Contenders are loaded' : ''
                                "
                                :icon-options="{ iconStyle: 'fas', iconName: 'fa-file-arrow-down' }"
                                @clicked="gotoReportGenerator()"
                            />
                        </div>
                    </AonCol>
                </AonRow>
            </AonContainer>
        </div>
        <div class="output-view d-flex w-full h-full">
            <OutputSideNav
                type="arena"
                :tech-areas-loading="loadingMaterialComponents"
                @reset-arena-output="resetArenaOutput"
            />
            <div
                v-show="arenaOutputStore.arenaOutputActiveTab === 1"
                class="tech-area-holder w-full h-full d-flex flex-column flex-grow-1"
            >
                <AonCoverLoading
                    v-if="
                        (loadingMaterialComponents ||
                            arenaOutputStore.arenaOutputTechAreaNodes.size === 0) &&
                        !showOutput
                    "
                    :loading="true"
                    title="Compiling Tech Areas"
                />
                <TechAreas
                    v-else
                    type="arena"
                    :table-data="tableData"
                    :loading="loadingMaterialComponents"
                />
            </div>
            <div
                v-show="arenaOutputStore.arenaOutputActiveTab === 2"
                class="arena-space-holder w-full"
            >
                <BenchmarkPeers
                    type="arena"
                    :key="arenaUpdater"
                    @everything-done-loading="setEverythingDoneLoading"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, inject, computed, nextTick, onBeforeUnmount, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useArenaOutputStore, useArenaStore, useReportGenerationStore } from '@/stores'
import { useAuth } from '@/auth/authPlugin'
import { getProductAlignmentSummary, pollForExport } from '@/api/productAlignment.js'

// import { useI18n } from 'vue-i18n'
import { useFlag } from '@unleash/proxy-client-vue'
import { downloadFile } from '@/composables/presignedUrlDownloader.js'

import OutputSideNav from '@/components/alignmentOutput/OutputSideNav.vue'
import TechAreas from '@/components/alignmentOutput/techAreas/TechAreas.vue'
import BenchmarkPeers from '@/components/alignmentOutput/benchmarkPeers/BenchmarkPeers.vue'

const props = defineProps({
    prompt: {
        type: String,
        default: '',
    },
})

const router = useRouter()
const logger = inject('logger')
const eventBus = inject('eventBus')
const arenaOutputStore = useArenaOutputStore()
const reportGenerationStore = useReportGenerationStore()
const arenaStore = useArenaStore()
const showExport = useFlag('ipAlpha.showExport')
const { user } = useAuth()

const showOutput = ref(false)
const peersLoading = ref(false)
const everythingDoneLoading = ref(false)
const loadingMaterialComponents = ref(true)
const arenaUpdater = ref(0)
const exportLoading = ref(false)
const generateLoading = ref(false)
const tableData = ref([])
let interval = ref(0)

// onBeforeMount(async () => {
//     arenaOutputStore.resetState(true)
// })

onMounted(async () => {
    setTimeout(async () => {
        clearInterval(interval)
        await nextTick()
        getMaterialComponentData()
    }, 1000)

    eventBus.on('set-arena-space-loading', (bool) => {
        peersLoading.value = bool
    })
})

onBeforeUnmount(() => {
    eventBus.all.clear()
})

const allowReportGen = computed(() => {
    return !targetCompany.value && (loadingMaterialComponents.value || peersLoading.value)
})

const backCopy = computed(() => {
    return arenaStore.boardGlobalDetails !== null ? 'Back to Arena Board' : 'Back to Home Page'
})

const targetCompany = computed(() => {
    let targetCompanyHasLength = arenaOutputStore.targetCompany?.length > 0 ? true : false
    if (targetCompanyHasLength) {
        let targetCompanyHasName =
            arenaOutputStore.targetCompany[0]?.aonEntityName === '' ? false : true
        return targetCompanyHasName
    }
})

const getMaterialComponentData = async () => {
    loadingMaterialComponents.value = true
    try {
        const { data } = await getProductAlignmentSummary(
            arenaOutputStore.outputJobPk,
            arenaOutputStore.techAreaIdCollection
        )

        tableData.value = data.nodes

        arenaOutputStore.arenaOutputAllTechAreaNodes = tableData.value
        arenaOutputStore.arenaOutputTechAreaNodes = new Set(tableData.value)
        arenaOutputStore.arenaOutputTechAreaSelections = new Set(tableData.value)
        arenaOutputStore.initLoad = true
    } catch (err) {
        logger.error(err)
    } finally {
        loadingMaterialComponents.value = false
    }
}

const goBack = () => {
    if (arenaStore.boardGlobalDetails) {
        router.push({
            name: 'Arena',
            params: { arenaSourceId: arenaStore.boardGlobalDetails.productAlignmentId },
        })
    } else {
        router.push({ name: 'Home' })
    }
}

const setEverythingDoneLoading = (bool) => {
    everythingDoneLoading.value = bool
}

const exportPatents = async () => {
    exportLoading.value = true
    getPAExportStatus()
    interval = setInterval(async () => {
        getPAExportStatus()
    }, 5000)
}
const getPAExportStatus = async () => {
    await pollForExport(
        arenaStore.boardGlobalDetails.productAlignmentId,
        async (data) => {
            // success
            clearInterval(interval)
            eventBus.emit('snacktime', {
                type: 'success',
                message: 'Export Successful. Downloading...',
            })
            downloadFile(data.exportPresignedUrl)
            exportLoading.value = false
        },
        (err) => {
            // failure
            eventBus.emit('snacktime', {
                type: 'error',
                message: 'Export Failed. Please contact Luke Shoop for further assistance.',
            })
            clearInterval(interval)
        }
    )
}

const gotoReportGenerator = async () => {
    generateLoading.value = true

    reportGenerationStore.forwardTargetCompanySelections('arena')
    router.push({ name: 'Create Report', query: { source: 'arena' } })
}

const resetArenaOutput = async () => {
    clearInterval(interval)
    await nextTick()
    arenaUpdater.value++
}
</script>

<style lang="scss" scoped>
.output-view {
    width: 100%;
    height: 100%;
    position: relative;

    .back-holder {
        cursor: pointer;
    }

    .tech-area-holder {
        min-height: 100vh;
        height: 10px;
    }

    :deep(.table-aips-latest) {
        border: none !important;
        .aips-table-wrap {
            border: none !important;
        }

        th {
            pointer-events: none;

            &:first-of-type {
                border-radius: 4px 0 0 0;
            }

            &:last-of-type {
                border-radius: 0 4px 0 0;
            }
        }
    }
}
</style>
